import React from "react";

import "./dashboard-loading.style.scss";
import { ReactComponent as Spinner } from "../../images/Spinner-Small.svg";

const DashboardLoading = ({ loadingMessage, fixedPosition }) => (
  <div className={`dashboard-loading-wrapper ${fixedPosition ? "fixed-position" : ""}`}>
    <div className="dashboard-loading">
      <Spinner />
      <span>{loadingMessage ? <p>{`${loadingMessage}`}</p> : null}</span>
    </div>
  </div>
);

export default DashboardLoading;
