import React from "react";

import "./date-range-select.style.scss";
import Calendar from "react-calendar";

class DateRangeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo
    };
  }

  drsoPresets = [
    {
      value: 0,
      label: "Today"
    },
    {
      value: 7,
      label: "Last 07 days"
    },
    {
      value: 30,
      label: "Last 30 days"
    },
    {
      value: 60,
      label: "Last 60 days"
    },
    {
      value: 90,
      label: "Last 90 days"
    }
  ];

  //#region  -------- Pre-Processors
  initDateRange = days => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let dateFrom = new Date();
    dateFrom.setTime(today.getTime() - days * 24 * 60 * 60 * 1000);

    // Check if Preset is within minDateRange
    if (this.props.minDateRange) {
      let tempMinDateRange = this.props.minDateRange;

      if (tempMinDateRange.getTime() > dateFrom.getTime()) {
        dateFrom = tempMinDateRange;
        // console.log("Changed Preset DateFrom Into:", tempMinDateRange);
      }
    }

    // console.log(today);
    // console.log(dateFrom);
    this.setState({ dateFrom: dateFrom, dateTo: today }, () => {
      //   console.log(this.state);
    });
  };
  //#endregion  ----- Pre-Processors

  //#region --------- UI Contollers
  onCalendarDateRangeChange = values => {
    // console.log(values);
    let tempDateTo = values[1];
    tempDateTo.setHours(0, 0, 0, 0);

    this.setState({ dateFrom: values[0], dateTo: tempDateTo });
  };

  onSelectPresetChange = event => {
    let presetValue = parseInt(event.currentTarget.value);

    this.initDateRange(presetValue);
  };

  onOKClick = () => {
    if (this.props.dateFrom && this.props.dateTo) {
      if (
        this.props.dateFrom.getTime() !== this.state.dateFrom.getTime() ||
        this.props.dateTo.getTime() !== this.state.dateTo.getTime()
      ) {
        this.props.changeDateRangeFilterState(null, this.state.dateFrom, this.state.dateTo);
      }
    } else if (this.state.dateFrom && this.state.dateTo) {
      this.props.changeDateRangeFilterState(null, this.state.dateFrom, this.state.dateTo);
    }

    this.setState({ showOptions: false });
  };

  onCancelClick = () => {
    this.setState({ showOptions: false, dateFrom: this.props.dateFrom, dateTo: this.props.dateTo });
  };

  showDRSOptions = event => {
    if (!this.state.showOptions && !event.currentTarget.classList.contains("drs-trigger-disabled")) {
      this.setState({ showOptions: true });
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  };

  getDateRangeLabel = () => {
    if (this.state.dateFrom && this.state.dateTo) {
      let isAPreset = this.isAPreset();
      //   console.log(isAPreset);

      if (isAPreset || isAPreset === 0) {
        let newLabel = "...";
        this.drsoPresets.forEach(preset => {
          if (preset.value === isAPreset) {
            newLabel = preset.label;
          }
        });

        return newLabel;
      } else {
        let dateFrom = this.state.dateFrom;
        let dateTo = this.state.dateTo;

        if (dateFrom.getTime() === dateTo.getTime()) {
          return `${this.formatDateString(dateFrom)}`;
        } else {
          return `${this.formatDateString(dateFrom)} - ${this.formatDateString(dateTo)}`;
        }
      }
    } else {
      return "Select Date";
    }
  };

  handleClickOutside = event => {
    if (this.state.showOptions) {
      let popUpElement = document.getElementById("date-range-select-id");

      if (!popUpElement.contains(event.target)) {
        this.onCancelClick();
        document.removeEventListener("mousedown", this.handleClickOutside);
      }
    }
  };
  //#endregion ------ UI Contollers

  //#region --------- Helper Functions
  isAPreset = () => {
    if (this.state.dateFrom && this.state.dateTo) {
      let tempDateTo = this.state.dateTo;
      tempDateTo = tempDateTo.setHours(0, 0, 0, 0);

      let today = new Date();
      today = today.setHours(0, 0, 0, 0);
      // console.log(this.state.dateFrom);
      // console.log(tempDateTo);
      // console.log(today);

      let isAPreset = false;
      if (tempDateTo === today) {
        let dateDiff = Math.abs(tempDateTo - this.state.dateFrom) / (24 * 60 * 60 * 1000);
        this.drsoPresets.forEach(option => {
          if (option.value === dateDiff) {
            isAPreset = dateDiff;
          }
        });
      }

      return isAPreset;
    } else {
      return false;
    }
  };

  isActivePreset = option => {
    if (this.state.dateFrom && this.state.dateTo) {
      let isAPreset = this.isAPreset();

      // console.log(isAPreset);
      // console.log(thisPreset);

      if (isAPreset) {
        if (option.value === isAPreset) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  formatDateString = date => {
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = `0${month}`;
    }

    let day = date.getDate().toString();
    if (day.length === 1) {
      day = `0${day}`;
    }

    let year = date
      .getFullYear()
      .toString()
      .substr(2);

    return `${month}/${day}/${year}`;
  };

  setAlignPopUpClass = alignment => {
    return `drs-options-align-${alignment}`;
  };
  //#endregion ------ Helper Functions

  componentDidUpdate(prevProps) {
    if (prevProps.dateFrom !== this.props.dateFrom && prevProps.dateTo !== this.props.dateTo) {
      if (this.props.dateFrom === null && this.props.dateTo === null) {
        this.setState({ dateFrom: null, dateTo: null });
        // console.log("Date Range Cleared...");
      }
    }

    if (
      prevProps.dateFrom === null &&
      this.state.dateFrom === null &&
      this.props.dateFrom !== null &&
      prevProps.dateTo === null &&
      this.state.dateTo === null &&
      this.props.dateTo !== null
    ) {
      this.setState({ dateFrom: this.props.dateFrom, dateTo: this.props.dateTo });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <div
        id="date-range-select-id"
        className={`date-range-select ${this.props.disabled ? "date-range-select-disabled" : ""}`}
      >
        <div
          className={`drs-trigger ${this.props.disabled ? "drs-trigger-disabled" : ""}`}
          onClick={this.showDRSOptions}
        >
          {this.getDateRangeLabel()}
        </div>
        {this.state.showOptions ? (
          <div className={`drs-options ${this.props.alignPopUp ? this.setAlignPopUpClass(this.props.alignPopUp) : ""}`}>
            <div className="drso-presets">
              <select value={`${this.isAPreset()}`} onChange={this.onSelectPresetChange}>
                <option value="false" disabled={true}>
                  Custom
                </option>
                {this.drsoPresets.map((option, oIndex) => (
                  <option key={`drso-${oIndex}`} value={`${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <Calendar
              selectRange={true}
              calendarType="US"
              maxDate={new Date()}
              minDate={this.props.minDateRange ? this.props.minDateRange : undefined}
              onChange={this.onCalendarDateRangeChange}
              value={[this.state.dateFrom, this.state.dateTo]}
            />
            <div className="drso-footer">
              <p className="drso-button btn-cancel" onClick={this.onCancelClick}>
                Cancel
              </p>
              <p className="drso-button btn-ok" onClick={this.onOKClick}>
                OK
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DateRangeSelect;
