import React from "react";

import "./dashboard.style.scss";
import "../common-styles/layout/sweetalert.style.scss";
import "../common-styles/layout/info-block.style.scss";
import "../common-styles/layout/fixed-pop-up.style.scss";
import "../common-styles/layout/action-buttons.style.scss";
import "../common-styles/layout/epic-character-group.style.scss";
import "../common-styles/layout/tooltip.style.scss";
import "../common-styles/fonts/font-formattings.scss";

import { INIT_CONFIG, DATABASE_STARTING_POINT_FALLBACK } from "../dashboard-config/dashboard-config";

import { calculateTotalDatewiseDataSetValueFor } from "../utility-functions/dashboard.utils";
import { firestore } from "../firebase/firebase.utils";
import swal from "@sweetalert/with-react";

import UserInfo from "../components/user-info/user-info.component";
import SidebarNav from "../components/sidebar-nav/sidebar-nav.component";
import ToolbarView from "../components/toolbar-view/toolbar-view.component";
import MainView from "../components/main-view/main-view.component";
import FooterView from "../components/footer-view/footer-view.component";
import PopUpView from "../components/pop-up/pop-up-view.component";
import DashboardLoading from "../components/loading/dashboard-loading.component";

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      // ---------------- -------------------- ---------------------
      // ----------- SENTINEL OVERVIEW COLLECTION STATES -----------
      // ---------------- -------------------- ---------------------
      sentinelOverviewCollection: null,
      sentinelOverviewDateRangeFilter: {
        active: false,
        dateFrom: this.getDateRangeFromFor(30),
        dateTo: this.getDateRangeTo(),
      },
      // ---------------- -------------------- ---------------------
      // ---------------- RECORDS COLLECTION STATES ----------------
      // ---------------- -------------------- ---------------------
      recordsCollection: null,
      recordsCollectionMetaData: null,
      recordsPaginatedDataCache: null,
      recordsPagination: {
        rowsPerPage: 25, // must be in the corresponding select element
        currentPage: 1,
        totalPages: null,
      },
      recordsSorting: {
        column: "lastReadAt", // [[MUST BE SAME AS recordsDateRangeFilter.by]] possible values {createdAt, lastReadAt}
        order: "desc",
      },
      recordsFilter: {
        by: "All", // must be in the corresponding select element
      },
      recordsDateRangeFilter: {
        by: "lastReadAt", // [[MUST BE SAME AS recordsSorting.column]] possible values {createdAt, lastReadAt}
        dateFrom: null,
        dateTo: null,
      },
      recordsSearch: {
        isActive: false,
        keyword: null,
        in: "licenseKey", // must be in the corresponding select element
      },
      // ---------------- -------------------- ---------------------
      // ------------------ KEYS COLLECTION STATES -----------------
      // ---------------- -------------------- ---------------------
      keysCollection: null,
      keysCollectionMetaData: null,
      keysPaginatedDataCache: null,
      keysPagination: {
        rowsPerPage: 25, // must be in the corresponding select element
        currentPage: 1,
        totalPages: null,
      },
      keysSorting: {
        column: "modifiedAt", // [[MUST BE SAME AS keysDateRangeFilter.by]] possible values {createdAt, modifiedAt}
        order: "desc",
      },
      keysFilter: {
        by: "All", // must be in the corresponding select element
      },
      keysDateRangeFilter: {
        by: "modifiedAt", // [[MUST BE SAME AS keysSorting.column]] possible values {createdAt, modifiedAt}
        dateFrom: null,
        dateTo: null,
      },
      keysSearch: {
        isActive: false,
        keyword: null,
        in: "licenseKey", // must be in the corresponding select element
      },
      // ---------------- -------------------- ---------------------
      // ---------------------- SHARED STATES ----------------------
      // ---------------- -------------------- ---------------------
      appOPState: INIT_CONFIG.appOPState,
      databaseStartingPoint: null,
      isHardReset: true,
      isLoadingQueued: false,
      loadingView: {
        is: true,
        message: "",
      },
      popUpView: {
        records: null,
        keys: null,
      },
      clipboard: {
        copy: null,
      },
      storedReadlogs: {},
    };
  }

  // ***************************************** CHANGE DASHBOARD STATE ***********************************************

  //-- SIDEBAR NAVIGATION HANDLER
  changeAppOperationalState = (e) => {
    let menuOPState = e.target.getAttribute("data-op-state");

    let initialLoadingNeeded = this.isInitialLoadingNeeded(menuOPState);

    if (initialLoadingNeeded) {
      let loadingMessage = `fetching ${menuOPState} collection...`;

      this.setState({
        appOPState: menuOPState,
        loadingView: {
          is: true,
          message: loadingMessage,
        },
      });
    } else {
      // MUST STOP loading, if the other collection was sitll on loading state before switching to this
      this.setState({
        appOPState: menuOPState,
        loadingView: {
          is: false,
          message: null,
        },
      });
    }
  };

  isInitialLoadingNeeded = (collectionType) => {
    let initialLoadingNeeded = false;

    switch (collectionType) {
      case "sentinel-overview":
        if (!this.state.sentinelOverviewCollection) {
          initialLoadingNeeded = true;
        }
        break;
      case "records":
        if (!this.state.recordsCollection) {
          initialLoadingNeeded = true;
        }
        break;
      case "keys":
        if (!this.state.keysCollection) {
          initialLoadingNeeded = true;
        }
        break;
      default:
        break;
    }

    return initialLoadingNeeded;
  };
  //-- SIDEBAR NAVIGATION HANDLER

  //-- RELOAD COLLECTIONS
  triggerReload = (isHardReset = true, paginationObject = null) => {
    let currentAppOPState = this.state.appOPState;

    if (currentAppOPState !== "sentinel-overview") {
      let currentCollectionMetaData;

      if (isHardReset) {
        currentCollectionMetaData = null;
      } else {
        currentCollectionMetaData = this.getCurrentCollectionMetaData(currentAppOPState);
      }

      if (!paginationObject) {
        paginationObject = {
          rowsPerPage: this.getCurrentPaginationDetails(currentAppOPState, "rowsPerPage"),
          currentPage: 1,
          totalPages: null,
        };
      }

      switch (currentAppOPState) {
        case "records":
          this.reloadRecordsCollection(paginationObject, currentCollectionMetaData, isHardReset);
          break;
        case "keys":
          this.reloadKeysCollection(paginationObject, currentCollectionMetaData, isHardReset);
          break;
        default:
          break;
      }
    } else {
      this.reloadSentinelOverview();
    }
  };

  reloadRecordsCollection = (paginationObject, currentCollectionMetaData, currentIsHardReset) => {
    console.log("------", "Trigger Record Reload", "------");
    this.setState({
      recordsPagination: paginationObject,
      recordsCollectionMetaData: currentCollectionMetaData,
      isHardReset: currentIsHardReset,
      loadingView: {
        is: true,
        message: null,
      },
    });
  };

  reloadKeysCollection = (paginationObject, currentCollectionMetaData, currentIsHardReset) => {
    console.log("------", "Trigger Keys Reload", "------");
    this.setState({
      keysPagination: paginationObject,
      keysCollectionMetaData: currentCollectionMetaData,
      isHardReset: currentIsHardReset,
      loadingView: {
        is: true,
        message: null,
      },
    });
  };

  reloadSentinelOverview = () => {
    console.log("------", "Trigger Overview Reload", "------");
    this.setState({
      loadingView: {
        is: true,
        message: null,
      },
    });
  };
  //-- RELOAD COLLECTIONS

  resetKeysCollection = () => {
    let keysPagination = {
      rowsPerPage: this.getCurrentPaginationDetails("keys", "rowsPerPage"),
      currentPage: 1,
      totalPages: null,
    };
    let keysSorting = {
      column: "modifiedAt",
      order: "desc",
    };
    let keysFilter = {
      by: "All",
    };
    let keysSearch = {
      isActive: false,
      keyword: null,
      in: "licenseKey",
    };
    let keysDateRangeFilter = {
      by: "modifiedAt", // [[MUST BE SAME AS keysSorting.column]] possible values {createdAt, modifiedAt}
      dateFrom: null,
      dateTo: null,
    };

    this.setState({
      isHardReset: true,
      keysPagination: keysPagination,
      keysSorting: keysSorting,
      keysFilter: keysFilter,
      keysSearch: keysSearch,
      keysDateRangeFilter: keysDateRangeFilter,
      keysCollectionMetaData: null,
      loadingView: {
        is: true,
        message: null,
      },
    });
  };

  changeSearchState = (state, word = null, column = null) => {
    switch (this.state.appOPState) {
      case "records":
        this.setState({
          recordsSearch: { isActive: state, keyword: word, in: column },
        });
        break;
      case "keys":
        this.setState({
          keysSearch: { isActive: state, keyword: word, in: column },
        });
        break;
      default:
        break;
    }

    this.triggerReload();
  };

  changeFilterState = (filter) => {
    switch (this.state.appOPState) {
      case "records":
        this.setState({
          recordsFilter: { by: filter },
        });
        break;
      case "keys":
        this.setState({
          keysFilter: { by: filter },
        });
        break;
      default:
        break;
    }

    this.triggerReload();
  };

  changeDateRangeFilterState = (by = null, dateFrom = null, dateTo = null, active = true) => {
    let tempBy;
    let tempDateFrom;
    let tempDateTo;
    let tempSortingObject;

    switch (this.state.appOPState) {
      case "sentinel-overview":
        tempDateFrom = active ? dateFrom : this.getDateRangeFromFor(30);
        tempDateTo = active ? dateTo : this.getDateRangeTo();
        this.setState(
          {
            sentinelOverviewDateRangeFilter: { active: active, dateFrom: tempDateFrom, dateTo: tempDateTo },
          },
          () => {
            // console.log(this.state.sentinelOverviewDateRangeFilter);
          }
        );
        break;
      case "records":
        tempBy = by ? by : this.state.recordsDateRangeFilter.by;
        tempDateFrom = dateFrom ? dateFrom : this.state.recordsDateRangeFilter.dateFrom;
        tempDateTo = dateTo ? dateTo : this.state.recordsDateRangeFilter.dateTo;

        tempSortingObject = this.getCurrentSortingDetails("all");
        tempSortingObject.column = tempBy;

        this.setState(
          {
            recordsDateRangeFilter: { by: tempBy, dateFrom: tempDateFrom, dateTo: tempDateTo },
            recordsSorting: tempSortingObject,
          },
          () => {
            // console.log(this.state.recordsDateRangeFilter);
            this.triggerReload();
          }
        );
        break;
      case "keys":
        tempBy = by ? by : this.state.keysDateRangeFilter.by;
        tempDateFrom = dateFrom ? dateFrom : this.state.keysDateRangeFilter.dateFrom;
        tempDateTo = dateTo ? dateTo : this.state.keysDateRangeFilter.dateTo;

        tempSortingObject = this.getCurrentSortingDetails("all");
        tempSortingObject.column = tempBy;

        this.setState(
          {
            keysDateRangeFilter: { by: tempBy, dateFrom: tempDateFrom, dateTo: tempDateTo },
            keysSorting: tempSortingObject,
          },
          () => {
            // console.log(this.state.keysDateRangeFilter);
            this.triggerReload();
          }
        );
        break;
      default:
        break;
    }
  };

  getCurrentDateRangeFilterDetails = (property, collectionType = null) => {
    let switchBy = collectionType ? collectionType : this.state.appOPState;
    // console.log(`switch by: ${switchBy}`);
    switch (switchBy) {
      case "records":
        if (property === "all") {
          return this.state.recordsDateRangeFilter;
        } else {
          return this.state.recordsDateRangeFilter[property];
        }
      // break;
      case "keys":
        if (property === "all") {
          return this.state.keysDateRangeFilter;
        } else {
          return this.state.keysDateRangeFilter[property];
        }
      // break;
      default:
        break;
    }
  };

  changeSortingState = (columnValue, toggle = false) => {
    let orderValue;

    if (toggle) {
      orderValue = this.getCurrentSortingDetails("order") === "desc" ? "asc" : "desc";
    } else {
      orderValue = this.getCurrentSortingDetails("order");
    }

    let tempSortingObject = {
      column: columnValue,
      order: orderValue,
    };

    let tempDateRangeFilterObject = this.getCurrentDateRangeFilterDetails("all");
    tempDateRangeFilterObject.by = tempSortingObject.column;
    // console.log(tempDateRangeFilterObject);

    switch (this.state.appOPState) {
      case "records":
        this.setState(
          {
            recordsSorting: tempSortingObject,
            recordsDateRangeFilter: tempDateRangeFilterObject,
          },
          () => {
            this.triggerReload();
          }
        );
        break;
      case "keys":
        this.setState(
          {
            keysSorting: tempSortingObject,
            keysDateRangeFilter: tempDateRangeFilterObject,
          },
          () => {
            this.triggerReload();
          }
        );
        break;
      default:
        break;
    }
  };

  getCurrentSortingDetails = (property, collectionType = null) => {
    let switchBy = collectionType ? collectionType : this.state.appOPState;
    // console.log(`switch by: ${switchBy}`);
    switch (switchBy) {
      case "records":
        if (property === "all") {
          return this.state.recordsSorting;
        } else {
          return this.state.recordsSorting[property];
        }
      // break;
      case "keys":
        if (property === "all") {
          return this.state.keysSorting;
        } else {
          return this.state.keysSorting[property];
        }
      // break;
      default:
        break;
    }
  };

  //--- Pagination UI State Handlers
  changeRowsPerPageState = (rowsPerPageValue) => {
    rowsPerPageValue = parseInt(rowsPerPageValue);
    let currTotalPages = this.getCurrentPaginationDetails(this.state.appOPState, "totalPages");
    let currTotalDocCount = this.getCurrentCollectionMetaData(this.state.appOPState, "totalDocCount");

    if (rowsPerPageValue >= currTotalDocCount && currTotalPages === 1) {
      let paginationObject;
      switch (this.state.appOPState) {
        case "records":
          paginationObject = this.state.recordsPagination;
          paginationObject.rowsPerPage = rowsPerPageValue;
          this.setState({ recordsPagination: paginationObject });
          break;
        case "keys":
          paginationObject = this.state.keysPagination;
          paginationObject.rowsPerPage = rowsPerPageValue;
          this.setState({ keysPagination: paginationObject });
          break;
        default:
          break;
      }
    } else {
      let tempPaginationObject = {
        rowsPerPage: rowsPerPageValue,
        currentPage: 1,
        totalPages: null,
      };

      let nonPaginatedCollectionData = this.getNonPaginatedCollectionData();

      if (nonPaginatedCollectionData) {
        tempPaginationObject.totalPages = Math.ceil(currTotalDocCount / rowsPerPageValue);
        this.setCurrRowsPerPageChangeForNPCs(nonPaginatedCollectionData, tempPaginationObject);
      } else {
        this.triggerReload(true, tempPaginationObject);
      }
    }
  };

  changeCurrentPageState = (operation) => {
    let validOP = false;
    let tempPaginationObject;

    switch (operation) {
      case "increase":
        validOP =
          this.getCurrentPaginationDetails(this.state.appOPState, "currentPage") + 1 >
          this.getCurrentPaginationDetails(this.state.appOPState, "totalPages")
            ? false
            : true;

        if (validOP) {
          tempPaginationObject = {
            rowsPerPage: this.getCurrentPaginationDetails(this.state.appOPState, "rowsPerPage"),
            currentPage: this.getCurrentPaginationDetails(this.state.appOPState, "currentPage") + 1,
            totalPages: this.getCurrentPaginationDetails(this.state.appOPState, "totalPages"),
          };
        }
        break;
      case "decrease":
        validOP = this.getCurrentPaginationDetails(this.state.appOPState, "currentPage") - 1 < 1 ? false : true;

        if (validOP) {
          tempPaginationObject = {
            rowsPerPage: this.getCurrentPaginationDetails(this.state.appOPState, "rowsPerPage"),
            currentPage: this.getCurrentPaginationDetails(this.state.appOPState, "currentPage") - 1,
            totalPages: this.getCurrentPaginationDetails(this.state.appOPState, "totalPages"),
          };
        }
        break;
      default:
        break;
    }

    if (validOP) {
      let nonPaginatedCollectionData = this.getNonPaginatedCollectionData();

      if (nonPaginatedCollectionData) {
        this.setCurrPageCollectionDataForNPCs(nonPaginatedCollectionData, tempPaginationObject);
      } else {
        let cachedCollectionDataSet = this.getCurrentPaginatedDataCache(this.state.appOPState)[
          tempPaginationObject.currentPage
        ];
        if (cachedCollectionDataSet) {
          this.useCachedCollectionDataSetForCurrentPage(cachedCollectionDataSet, tempPaginationObject);
        } else {
          this.triggerReload(false, tempPaginationObject);
        }
      }
    }
  };

  getCurrentPaginationDetails = (currentAppOPState, property = null) => {
    switch (currentAppOPState) {
      case "records":
        if (property) {
          return this.state.recordsPagination[property];
        } else {
          return this.state.recordsPagination;
        }
      // break;
      case "keys":
        if (property) {
          return this.state.keysPagination[property];
        } else {
          return this.state.keysPagination;
        }
      // break;
      default:
        break;
    }
  };

  getCurrentCollectionMetaData = (currentAppOPState, property = null) => {
    switch (currentAppOPState) {
      case "records":
        if (property) {
          let tempProperty;
          try {
            tempProperty = this.state.recordsCollectionMetaData[property];
          } catch (error) {
            tempProperty = 0;
          }
          return tempProperty;
        } else {
          return this.state.recordsCollectionMetaData;
        }
      // break;
      case "keys":
        if (property) {
          let tempProperty;
          try {
            tempProperty = this.state.keysCollectionMetaData[property];
          } catch (error) {
            tempProperty = 0;
          }
          return tempProperty;
        } else {
          return this.state.keysCollectionMetaData;
        }
      // break;
      default:
        break;
    }
  };
  //--- Pagination UI State Handlers

  //--- Paginated Data Cache Handlers
  getCurrentPaginatedDataCache = (currentAppOPState) => {
    switch (currentAppOPState) {
      case "records":
        return this.state.recordsPaginatedDataCache;
      // break;
      case "keys":
        return this.state.keysPaginatedDataCache;
      // break;
      default:
        break;
    }
  };

  useCachedCollectionDataSetForCurrentPage = (collectionData, tempPaginationObject) => {
    console.log("Using Cached Data");
    switch (this.state.appOPState) {
      case "records":
        this.setState({ recordsCollection: collectionData, recordsPagination: tempPaginationObject });
        break;
      case "keys":
        this.setState({ keysCollection: collectionData, keysPagination: tempPaginationObject });
        break;
      default:
        break;
    }
  };
  //--- Paginated Data Cache Handlers

  //--- Pagination Handlers for Non-Paginated Collections(NPCs)
  getNonPaginatedCollectionData = () => {
    switch (this.state.appOPState) {
      case "records":
        return this.state.recordsNonPaginatedCollectionData;
      // break;
      case "keys":
        return this.state.keysNonPaginatedCollectionData;
      // break;
      default:
        break;
    }
  };

  setCurrRowsPerPageChangeForNPCs = (nonPaginatedCollectionData, tempPaginationObject) => {
    // console.log("-----CurrRowsPerPageChange-----");
    // console.log(nonPaginatedCollectionData);
    // console.log(tempPaginationObject);
    // console.log("-----CurrRowsPerPageChange-----");

    let currCollectionData = nonPaginatedCollectionData.slice(0, tempPaginationObject.rowsPerPage);

    // console.log(currCollectionData);

    this.saveCurrPaginationChangeForNPCs(tempPaginationObject, currCollectionData);
  };

  setCurrPageCollectionDataForNPCs = (nonPaginatedCollectionData, tempPaginationObject) => {
    // console.log("-----CurrPageChange-----");
    // console.log(nonPaginatedCollectionData);
    // console.log(tempPaginationObject);
    // console.log("-----CurrPageChange-----");

    let startingIndex = tempPaginationObject.rowsPerPage * (tempPaginationObject.currentPage - 1);
    let endingIndex = tempPaginationObject.rowsPerPage * tempPaginationObject.currentPage;
    let currCollectionData = nonPaginatedCollectionData.slice(startingIndex, endingIndex);

    // console.log("Starting Index: ", startingIndex);
    // console.log("Ending Index: ", endingIndex);
    // console.log(currCollectionData);

    this.saveCurrPaginationChangeForNPCs(tempPaginationObject, currCollectionData);
  };

  saveCurrPaginationChangeForNPCs = (tempPaginationObject, currCollectionData) => {
    let currCollectionMetaData = this.getCurrentCollectionMetaData(this.state.appOPState);
    currCollectionMetaData.currentDocCount = currCollectionData.length;

    switch (this.state.appOPState) {
      case "records":
        this.setState({
          recordsPagination: tempPaginationObject,
          recordsCollection: currCollectionData,
          recordsCollectionMetaData: currCollectionMetaData,
        });
        break;
      case "keys":
        this.setState({
          keysPagination: tempPaginationObject,
          keysCollection: currCollectionData,
          keysCollectionMetaData: currCollectionMetaData,
        });
        break;
      default:
        break;
    }
  };
  //--- Pagination Handlers for Non-Paginated Collections

  changeClipboardState = (value = null) => {
    this.setState({ clipboard: { copy: value } });
  };

  togglePopUpView = (docID = null) => {
    let newPopUpView = this.state.popUpView;

    switch (this.state.appOPState) {
      case "records":
        newPopUpView.records = docID;
        break;
      case "keys":
        newPopUpView.keys = docID;
        break;
      default:
        break;
    }

    this.setState({ popUpView: newPopUpView });
  };

  setStoredReadlogs = (docID, readlog) => {
    let readlogObject = this.state.storedReadlogs;

    readlogObject[docID] = readlog;
    this.setState({ storedReadlogs: readlogObject }, () => {
      // console.log(this.state.storedReadlogs);
    });
  };

  resetAllStoredReadlogs = (message) => {
    this.setState({ storedReadlogs: {} });
  };

  // ************************************************ FIREBASE *******************************************************
  getSentinelOverviewCollection = (callBackFunction = null) => {
    console.log("FETCHING OVERVIEW..");
    let collectionRef = firestore.collection("overview");
    let collectionQuery = collectionRef;

    collectionQuery
      .get()
      .then((collectionSnapshot) => {
        let tempSentinelOverview = {};
        collectionSnapshot.docs.forEach((doc) => {
          if (!tempSentinelOverview[doc.id]) {
            tempSentinelOverview[doc.id] = {};
          }

          tempSentinelOverview[doc.id] = {
            ...doc.data(),
          };
        });

        // -- Set Database Starting Point
        let tempDatabaseStartingPoint = null;
        try {
          tempDatabaseStartingPoint = new Date(tempSentinelOverview.summary.initialDate.seconds * 1000);
          tempDatabaseStartingPoint.setHours(0, 0, 0, 0);
        } catch (error) {
          tempDatabaseStartingPoint = new Date(DATABASE_STARTING_POINT_FALLBACK);
          tempDatabaseStartingPoint.setHours(0, 0, 0, 0);
          // console.log("Cannot set Initital Database Date");
        }
        // -- Set Database Starting Point

        if (Object.keys(tempSentinelOverview).length > 0) {
          if (callBackFunction) {
            this.setState(
              {
                sentinelOverviewCollection: tempSentinelOverview,
                databaseStartingPoint: tempDatabaseStartingPoint,
                isLoadingQueued: true,
              },
              () => {
                callBackFunction();
              }
            );
          } else {
            this.setState({
              sentinelOverviewCollection: tempSentinelOverview,
              databaseStartingPoint: tempDatabaseStartingPoint,
              loadingView: { is: false, message: null },
            });
          }
        } else {
          this.setState({
            sentinelOverviewCollection: tempSentinelOverview,
            databaseStartingPoint: tempDatabaseStartingPoint,
            recordsCollection: {},
            keysCollection: {},
            loadingView: { is: false, message: null },
          });
        }
      })
      .catch((error) => {
        swal("Something Went Wrong!", `${error.message}`, { button: false, icon: "error" });
        this.setState({ loadingView: { is: false, message: null } });

        console.log(error);
      });
  };

  // --- Create Firebase Queries for collectionType
  createRecordsFirebaseQuery = (recordsRef) => {
    let recordsQuery = recordsRef;
    let filterColumn = this.state.recordsFilter.by !== "All" ? this.state.recordsFilter.by : null;
    let searchColumn = this.state.recordsSearch.isActive ? this.state.recordsSearch.in : null;

    let totalDocCount = null;
    let totalPages = 1;

    if (filterColumn) {
      if (filterColumn === "Active") {
        recordsQuery = recordsQuery.where(`isBlocked`, "==", false);
      } else {
        recordsQuery = recordsQuery.where(`is${filterColumn}`, "==", true);
      }
    }

    recordsQuery = recordsQuery.orderBy(
      this.getCurrentSortingDetails("column", "records"),
      this.getCurrentSortingDetails("order", "records")
    );

    if (searchColumn) {
      recordsQuery = recordsQuery.where(searchColumn, "==", this.state.recordsSearch.keyword);
    } else {
      // --Date Range Filter From
      let tempDateFrom;
      if (this.state.recordsDateRangeFilter.dateFrom) {
        tempDateFrom = this.state.recordsDateRangeFilter.dateFrom;
      } else {
        tempDateFrom = this.getInitialCollectionRetrievePointDateFrom("records");
      }
      recordsQuery = recordsQuery.where(this.state.recordsDateRangeFilter.by, ">=", tempDateFrom);

      // --Date Range Filter To
      let tempDateTo;
      if (this.state.recordsDateRangeFilter.dateTo) {
        tempDateTo = this.state.recordsDateRangeFilter.dateTo;
      } else {
        tempDateTo = this.getInitialCollectionRetrievePointDateTo("records");
      }
      tempDateTo.setHours(23, 59, 59, 999);
      recordsQuery = recordsQuery.where(this.state.recordsDateRangeFilter.by, "<=", tempDateTo);

      if (this.state.recordsSorting.column !== "lastReadAt") {
        // --Paginate Query
        let paginatedResults = this.getPaginatedQueryFor(
          "records",
          recordsQuery,
          filterColumn,
          tempDateFrom,
          tempDateTo
        );
        recordsQuery = paginatedResults[0];
        totalDocCount = paginatedResults[1];
        totalPages = paginatedResults[2];
      }
    }

    return [recordsQuery, totalDocCount, totalPages];
  };

  createKeysFirebaseQuery = (keysRef) => {
    let keysQuery = keysRef;
    let filterColumn = this.state.keysFilter.by !== "All" ? this.state.keysFilter.by : null;
    let searchColumn = this.state.keysSearch.isActive ? this.state.keysSearch.in : null;

    let totalDocCount = null;
    let totalPages = 1;

    if (filterColumn) {
      if (filterColumn === "Active") {
        keysQuery = keysQuery.where(`isBlocked`, "==", false);
      } else {
        keysQuery = keysQuery.where(`is${filterColumn}`, "==", true);
      }
    }

    keysQuery = keysQuery.orderBy(
      this.getCurrentSortingDetails("column", "keys"),
      this.getCurrentSortingDetails("order", "keys")
    );

    if (searchColumn) {
      keysQuery = keysQuery.where(searchColumn, "==", this.state.keysSearch.keyword);
    } else {
      // --Date Range Filter From
      let tempDateFrom;
      if (this.state.keysDateRangeFilter.dateFrom) {
        tempDateFrom = this.state.keysDateRangeFilter.dateFrom;
      } else {
        tempDateFrom = this.getInitialCollectionRetrievePointDateFrom("keys");
      }
      keysQuery = keysQuery.where(this.state.keysDateRangeFilter.by, ">=", tempDateFrom);

      // --Date Range Filter To
      let tempDateTo;
      if (this.state.keysDateRangeFilter.dateTo) {
        tempDateTo = this.state.keysDateRangeFilter.dateTo;
      } else {
        tempDateTo = this.getInitialCollectionRetrievePointDateTo("keys");
      }
      tempDateTo.setHours(23, 59, 59, 999);
      keysQuery = keysQuery.where(this.state.keysDateRangeFilter.by, "<=", tempDateTo);

      // --Paginate Query
      let paginatedResults = this.getPaginatedQueryFor("keys", keysQuery, filterColumn, tempDateFrom, tempDateTo);
      keysQuery = paginatedResults[0];
      totalDocCount = paginatedResults[1];
      totalPages = paginatedResults[2];
    }

    return [keysQuery, totalDocCount, totalPages];
  };
  // --- Create Firebase Queries for collectionType

  // --- Firebase Pagination
  getPaginatedQueryFor = (collectionType, collectionQuery, filterColumn, tempDateFrom, tempDateTo) => {
    //#region --- Init/Get currentTotalDocCount
    let currentCollectionMetaData = this.getCurrentCollectionMetaData(collectionType);
    let currentTotalDocCount;

    if (!currentCollectionMetaData) {
      let capitalizedCollectionType = `${collectionType.slice(0, 1).toUpperCase()}${collectionType.slice(1)}`;
      // console.log(capitalizedCollectionType);

      if (filterColumn) {
        if (filterColumn === "Active") {
          currentTotalDocCount = this.getActiveDocCountFor(capitalizedCollectionType, tempDateFrom, tempDateTo);
        } else if (filterColumn === "Blocked") {
          currentTotalDocCount = this.getNetBlockedDocCountFor(capitalizedCollectionType, tempDateFrom, tempDateTo);
        } else {
          let overviewFilterColumn;

          let tempFilterColumnArray = filterColumn.slice(0).split(/(?=[A-Z])/);
          if (tempFilterColumnArray.length > 1) {
            let newFilterColumnString = "";
            for (let i = 0; i < tempFilterColumnArray.length; i++) {
              newFilterColumnString += i === 0 ? `${tempFilterColumnArray[i].toLowerCase()}` : tempFilterColumnArray[i];
            }
            overviewFilterColumn = `${newFilterColumnString}${capitalizedCollectionType}`;
          } else {
            overviewFilterColumn = `${filterColumn.toLowerCase()}${capitalizedCollectionType}`;
          }
          // console.log(overviewFilterColumn);

          currentTotalDocCount = calculateTotalDatewiseDataSetValueFor(
            overviewFilterColumn,
            tempDateFrom,
            tempDateTo,
            this.state.sentinelOverviewCollection
          );
        }
      } else {
        currentTotalDocCount = this.getAllDocCountFor(collectionType, tempDateFrom, tempDateTo);
      }
    } else {
      currentTotalDocCount = this.getCurrentCollectionMetaData(collectionType, "totalDocCount");
    }
    //#endregion --- Init/Get currentTotalDocCount

    //--- Set currentTotalPages
    let collectionRowsPerPage = this.getCurrentPaginationDetails(collectionType, "rowsPerPage");
    let currentTotalPages = Math.ceil(currentTotalDocCount / collectionRowsPerPage);
    //--- Set currentTotalPages

    //--- Create Limited Query
    let collectionCurrentPage = this.getCurrentPaginationDetails(collectionType, "currentPage");
    if (collectionCurrentPage > 1) {
      let currentQueryCursers = this.getCurrentCollectionMetaData(collectionType, "queryCursers");

      if (currentQueryCursers[collectionCurrentPage - 2]) {
        collectionQuery = collectionQuery.startAfter(currentQueryCursers[collectionCurrentPage - 2]);
      } else {
        collectionQuery = null;
      }
    }

    if (collectionQuery) {
      collectionQuery = collectionQuery.limit(collectionRowsPerPage);
    }
    //--- Create Limited Query

    if (currentTotalDocCount <= 0) {
      collectionQuery = null;
    }

    return [collectionQuery, currentTotalDocCount, currentTotalPages];
  };

  getAllDocCountFor = (collectionType, tempDateFrom, tempDateTo) => {
    let allDocs;

    if (collectionType === "records") {
      allDocs = calculateTotalDatewiseDataSetValueFor(
        `activatedRecords`,
        tempDateFrom,
        tempDateTo,
        this.state.sentinelOverviewCollection
      );
    }

    if (collectionType === "keys") {
      let manuallyCreatedKeys = calculateTotalDatewiseDataSetValueFor(
        `manuallyCreatedKeys`,
        tempDateFrom,
        tempDateTo,
        this.state.sentinelOverviewCollection
      );

      let autoCreatedKeys = calculateTotalDatewiseDataSetValueFor(
        `autoCreatedKeys`,
        tempDateFrom,
        tempDateTo,
        this.state.sentinelOverviewCollection
      );

      allDocs = manuallyCreatedKeys + autoCreatedKeys;
    }

    return allDocs;
  };

  getActiveDocCountFor = (capitalizedCollectionType, tempDateFrom, tempDateTo) => {
    let activeDocCount;

    let allDocs = this.getAllDocCountFor(capitalizedCollectionType.toLowerCase(), tempDateFrom, tempDateTo);
    let netBlockedDocs = this.getNetBlockedDocCountFor(capitalizedCollectionType, tempDateFrom, tempDateTo);

    // console.log("ALL", allDocs);
    // console.log("NET BLOCKED", netBlockedDocs);

    activeDocCount = allDocs - netBlockedDocs;

    return activeDocCount;
  };

  getNetBlockedDocCountFor = (capitalizedCollectionType, tempDateFrom, tempDateTo) => {
    let netBlockedDocs;

    let blockedDocs = calculateTotalDatewiseDataSetValueFor(
      `blocked${capitalizedCollectionType}`,
      tempDateFrom,
      tempDateTo,
      this.state.sentinelOverviewCollection
    );

    let unblockOffset = calculateTotalDatewiseDataSetValueFor(
      `unblocked${capitalizedCollectionType}Offset`,
      tempDateFrom,
      tempDateTo,
      this.state.sentinelOverviewCollection
    );

    // console.log("BLOCKED", blockedDocs);
    // console.log("BLOCKED OFFSET", unblockOffset);

    netBlockedDocs = blockedDocs - unblockOffset;

    return netBlockedDocs;
  };
  // --- Firebase Pagination

  // -- Retrieve & Save Data From Firebase for collectionType
  getDataCollection = (collectionType) => {
    console.log(`FETCHING ${collectionType.toUpperCase()}..`);
    let collectionRef = firestore.collection(collectionType);
    let collectionQuery;

    let firebaseQueryResults;
    let totalDocCount;
    let totalPages;

    switch (collectionType) {
      case "records":
        firebaseQueryResults = this.createRecordsFirebaseQuery(collectionRef);
        break;
      case "keys":
        firebaseQueryResults = this.createKeysFirebaseQuery(collectionRef);
        break;
      default:
        break;
    }

    collectionQuery = firebaseQueryResults[0];
    totalDocCount = firebaseQueryResults[1];
    totalPages = firebaseQueryResults[2];

    if (collectionQuery) {
      collectionQuery
        .get()
        .then((collectionSnapshot) => {
          let collectionData = [];
          collectionSnapshot.docs.forEach((doc) => {
            let tempData = {
              docID: doc.id,
              ...doc.data(),
            };
            collectionData.push(tempData);
          });

          let queryCurserForNextPage = null;
          let nonPaginatedCollectionData = null;
          if (!totalDocCount) {
            //--- Init FrontEnd Pagination for Queries without Overview support
            let collectionRowsPerPage = this.getCurrentPaginationDetails(collectionType, "rowsPerPage");
            totalDocCount = collectionData.length;
            totalPages = Math.ceil(totalDocCount / collectionRowsPerPage);

            totalPages = totalPages === 0 ? 1 : totalPages;
            console.log("totalPages:", totalPages);

            nonPaginatedCollectionData = collectionData.slice(0);
            collectionData = nonPaginatedCollectionData.slice(0, collectionRowsPerPage);
            console.log(nonPaginatedCollectionData);
            // console.log(collectionData);
            //--- Init FrontEnd Pagination for Queries without Overview support
          } else {
            queryCurserForNextPage = collectionSnapshot.docs[collectionSnapshot.docs.length - 1];
          }

          this.saveDataCollection(
            collectionType,
            collectionData,
            totalDocCount,
            totalPages,
            queryCurserForNextPage,
            nonPaginatedCollectionData
          );
        })
        .catch((error) => {
          swal("Something Went Wrong!", `${error.message}`, { button: false, icon: "error" });
          // this.setState({ loadingView: { is: false, message: null } });
          this.saveDataCollection(collectionType, []);

          console.log(error);
        });
    } else {
      this.saveDataCollection(collectionType, []);
    }
  };

  saveDataCollection = (
    collectionType,
    collectionData = [],
    totalDocCount = 0,
    totalPages = 1,
    queryCurserForNextPage = null,
    nonPaginatedCollectionData = null
  ) => {
    let currentCollectionMetaData = this.getCurrentCollectionMetaData(collectionType);
    let currentPaginationDetails = this.getCurrentPaginationDetails(collectionType);
    let currentPaginatedDataCache = this.getCurrentPaginatedDataCache(collectionType);

    // --- Clear Cache
    if (currentPaginationDetails.currentPage === 1) {
      currentPaginatedDataCache = null;
    }
    // --- Clear Cache

    if (!currentCollectionMetaData) {
      currentCollectionMetaData = {};
    }

    if (collectionData.length > 0 && queryCurserForNextPage) {
      // --- Set Query Cursers
      let currentQueryCursers;
      if (!currentCollectionMetaData.queryCursers) {
        currentQueryCursers = [];
      } else {
        currentQueryCursers = currentCollectionMetaData.queryCursers;
      }

      let collectionCurrentPage = currentPaginationDetails.currentPage;
      let queryCurserIndexForNextPage = collectionCurrentPage - 1;

      if (!currentQueryCursers[queryCurserIndexForNextPage]) {
        currentQueryCursers.push(queryCurserForNextPage);
      }

      currentCollectionMetaData.queryCursers = currentQueryCursers;
      // --- Set Query Cursers

      // --- Create Paginated Data Cache
      if (!currentPaginatedDataCache) {
        currentPaginatedDataCache = {};
      }

      // TODO update totalDocCount in first & last page upon soft refresh
      currentPaginatedDataCache[collectionCurrentPage] = collectionData;
      // --- Create Paginated Data Cache
    }

    // --- Set collectionMetaData
    currentCollectionMetaData.totalDocCount = totalDocCount;
    currentCollectionMetaData.currentDocCount = collectionData.length;
    // --- Set collectionMetaData

    // --- Set collectionPaginationDetails
    currentPaginationDetails.totalPages = totalPages;
    // --- Set collectionPaginationDetails

    switch (collectionType) {
      case "records":
        this.setState(
          {
            loadingView: { is: false, message: null },
            isLoadingQueued: false,
            recordsCollection: collectionData,
            recordsCollectionMetaData: currentCollectionMetaData,
            recordsPagination: currentPaginationDetails,
            recordsPaginatedDataCache: currentPaginatedDataCache,
            recordsNonPaginatedCollectionData: nonPaginatedCollectionData,
          },
          () => {
            // console.log(this.state.recordsCollection);
            // console.log(this.state.recordsCollectionMetaData);
            // console.log(this.state.recordsPagination);
            // console.log(this.state.recordsPaginatedDataCache);
          }
        );
        break;
      case "keys":
        this.setState(
          {
            loadingView: { is: false, message: null },
            isLoadingQueued: false,
            keysCollection: collectionData,
            keysCollectionMetaData: currentCollectionMetaData,
            keysPagination: currentPaginationDetails,
            keysPaginatedDataCache: currentPaginatedDataCache,
            keysNonPaginatedCollectionData: nonPaginatedCollectionData,
          },
          () => {
            // console.log(this.state.keysCollection);
            // console.log(this.state.keysCollectionMetaData);
            // console.log(this.state.keysPagination);
            // console.log(this.state.keysPaginatedDataCache);
          }
        );
        break;
      default:
        break;
    }
  };

  // [NOT IN USE]
  getSortedDataCollectionFor = (collectionData, orderBy) => {
    let sortedCollectionData = collectionData.slice(0); //MUST make a copy of the array!

    sortedCollectionData.sort((a, b) => {
      if (orderBy.order === "asc") {
        return a[orderBy.column]["seconds"] - b[orderBy.column]["seconds"];
      } else {
        return b[orderBy.column]["seconds"] - a[orderBy.column]["seconds"];
      }
    });

    // console.log(collectionData, sortedCollectionData, orderBy);
    return sortedCollectionData;
  };
  // -- Retrieve & Save Data From Firebase for collectionType

  updateKeysCollection = (licenseKey = false) => {
    setTimeout(() => {
      this.resetKeysCollection();
      // console.log("updating!");
    }, 1000);

    if (licenseKey && this.state.recordsCollection) {
      let tempRecordsCollection = this.state.recordsCollection;
      let updateRecords = tempRecordsCollection.some((record) => {
        return record.licenseKey === licenseKey ? true : false;
      });

      if (updateRecords) {
        // console.log(licenseKey);

        //TODO Timeout is not optimal
        setTimeout(() => {
          this.getDataCollection("records");
          // console.log("updating!");
        }, 1500);
      }
    }
  };

  // ********************************************* PRE-PROCESSORS ****************************************************
  getDateRangeFromFor = (days) => {
    let dateFrom = new Date();
    dateFrom.setTime(dateFrom.getTime() - days * 24 * 60 * 60 * 1000);
    dateFrom.setHours(0, 0, 0, 0);

    // console.log(dateFrom);
    return dateFrom;
  };

  getDateRangeTo = () => {
    let today = new Date();
    let newToday = new Date();
    // newToday.setTime(today.setHours(23, 59, 59, 999));
    newToday.setTime(today.setHours(0, 0, 0, 0));
    // console.log(newToday);

    return newToday;
  };

  getInitialCollectionRetrievePointDateFrom = (collectionType = null) => {
    let startingPreset = this.getDateRangeFromFor(30);

    let tempDatabaseStartingPoint;
    if (this.state.databaseStartingPoint) {
      tempDatabaseStartingPoint = this.state.databaseStartingPoint;
    } else {
      tempDatabaseStartingPoint = new Date(DATABASE_STARTING_POINT_FALLBACK);
      tempDatabaseStartingPoint.setHours(0, 0, 0, 0);
    }

    if (tempDatabaseStartingPoint.getTime() > startingPreset.getTime()) {
      startingPreset = tempDatabaseStartingPoint;
    }

    if (collectionType) {
      let tempDateRangeFilter;
      switch (collectionType) {
        case "records":
          tempDateRangeFilter = this.state.recordsDateRangeFilter;
          tempDateRangeFilter.dateFrom = startingPreset;

          this.setState({ recordsDateRangeFilter: tempDateRangeFilter });
          break;
        case "keys":
          tempDateRangeFilter = this.state.keysDateRangeFilter;
          tempDateRangeFilter.dateFrom = startingPreset;

          this.setState({ keysDateRangeFilter: tempDateRangeFilter });
          break;
        default:
          break;
      }
    }

    return startingPreset;
  };

  getInitialCollectionRetrievePointDateTo = (collectionType = null) => {
    let tempDateTo = this.getDateRangeTo();

    if (collectionType) {
      let tempDateRangeFilter;
      switch (collectionType) {
        case "records":
          tempDateRangeFilter = this.state.recordsDateRangeFilter;
          tempDateRangeFilter.dateTo = tempDateTo;

          this.setState({ recordsDateRangeFilter: tempDateRangeFilter });
          break;
        case "keys":
          tempDateRangeFilter = this.state.keysDateRangeFilter;
          tempDateRangeFilter.dateTo = tempDateTo;

          this.setState({ keysDateRangeFilter: tempDateRangeFilter });
          break;
        default:
          break;
      }
    }

    return tempDateTo;
  };

  // ********************************************* REACT Functions ***************************************************
  componentDidMount() {
    // Trigger Data Collections On Load
    this.setState({ loadingView: { is: true, message: `fetching ${this.state.appOPState} collection...` } });
  }

  componentDidUpdate() {
    if (!this.state.isLoadingQueued) {
      // Fetch/Update Sentinel-Overview Collection
      if (this.state.appOPState === "sentinel-overview" && this.state.loadingView.is) {
        this.getSentinelOverviewCollection();
      }

      // Fetch/Update Records Collection
      if (this.state.appOPState === "records" && this.state.loadingView.is) {
        if (this.state.isHardReset) {
          this.getSentinelOverviewCollection(() => {
            this.getDataCollection("records");
          });
        } else {
          this.getDataCollection("records");
        }
      }

      // Fetch/Update Keys Collection
      if (this.state.appOPState === "keys" && this.state.loadingView.is) {
        if (this.state.isHardReset) {
          this.getSentinelOverviewCollection(() => {
            this.getDataCollection("keys");
          });
        } else {
          this.getDataCollection("keys");
        }
      }
    }
  }

  render() {
    return (
      <div className='dashboard-ui'>
        <div className='sidebar'>
          <UserInfo currentUser={this.props.currentUser} />
          <SidebarNav appOPState={this.state.appOPState} changeAppOperationalState={this.changeAppOperationalState} />
        </div>

        <div className='main-section'>
          {/* ************ DASHBOARD LOADING ************* */}
          {this.state.loadingView.is ? <DashboardLoading loadingMessage={this.state.loadingView.message} /> : null}
          {/* ************ DASHBOARD LOADING ************* */}
          <ToolbarView
            appOPState={this.state.appOPState}
            databaseStartingPoint={this.state.databaseStartingPoint}
            loadingView={this.state.loadingView}
            recordsSearch={this.state.recordsSearch}
            keysSearch={this.state.keysSearch}
            changeSearchState={this.changeSearchState}
            recordsFilter={this.state.recordsFilter}
            keysFilter={this.state.keysFilter}
            changeFilterState={this.changeFilterState}
            sentinelOverviewDateRangeFilter={this.state.sentinelOverviewDateRangeFilter}
            recordsDateRangeFilter={this.state.recordsDateRangeFilter}
            keysDateRangeFilter={this.state.keysDateRangeFilter}
            changeDateRangeFilterState={this.changeDateRangeFilterState}
            triggerReload={this.triggerReload}
            resetAllStoredReadlogs={this.resetAllStoredReadlogs}
          />
          <MainView
            appOPState={this.state.appOPState}
            togglePopUpView={this.togglePopUpView}
            sentinelOverviewCollection={this.state.sentinelOverviewCollection}
            sentinelOverviewDateRangeFilter={this.state.sentinelOverviewDateRangeFilter}
            recordsCollection={this.state.recordsCollection}
            keysCollection={this.state.keysCollection}
            recordsSorting={this.state.recordsSorting}
            keysSorting={this.state.keysSorting}
            changeSortingState={this.changeSortingState}
            changeClipboardState={this.changeClipboardState}
            updateKeysCollection={this.updateKeysCollection}
          />
          <PopUpView
            appOPState={this.state.appOPState}
            popUpView={this.state.popUpView}
            recordsCollection={this.state.recordsCollection}
            keysCollection={this.state.keysCollection}
            storedReadlogs={this.state.storedReadlogs}
            setStoredReadlogs={this.setStoredReadlogs}
            togglePopUpView={this.togglePopUpView}
            updateKeysCollection={this.updateKeysCollection}
            loadingView={this.state.loadingView}
            recordsSorting={this.state.recordsSorting}
            keysSorting={this.state.keysSorting}
            triggerReload={this.triggerReload}
            resetAllStoredReadlogs={this.resetAllStoredReadlogs}
          />
          <FooterView
            appOPState={this.state.appOPState}
            recordsCollectionMetaData={this.state.recordsCollectionMetaData}
            recordsPagination={this.state.recordsPagination}
            keysCollectionMetaData={this.state.keysCollectionMetaData}
            keysPagination={this.state.keysPagination}
            changeRowsPerPageState={this.changeRowsPerPageState}
            changeCurrentPageState={this.changeCurrentPageState}
            clipboardState={this.state.clipboard}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
