import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./calendar.style.scss";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      yearList: null,
      activeDateRange: {
        from: null,
        to: null
      },
      currentYear: null,
      currentMonth: null,
      currentActiveMonthRange: null, // Must be changed along with currentYear
      isButtonInactive: {
        prev: false,
        next: false
      },
      dateSelect: {
        visible: false,
        by: null
      }
    };
  }

  monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  //#region ------------------------------- CALENDAR FUNCTIONS --------------------------------
  initCalendarView = yearWiseDateObject => {
    if (yearWiseDateObject) {
      let initYearList = Object.keys(yearWiseDateObject);

      if (initYearList.length > 0) {
        // Find Active Date Range
        let fromMonthAndDay = this.getActiveEndPointFor(initYearList[0], "first");
        let toMonthAndDay = this.getActiveEndPointFor(initYearList[initYearList.length - 1], "last");

        let initActiveDateRange = {
          from: [parseInt(initYearList[0]), parseInt(fromMonthAndDay[0]), parseInt(fromMonthAndDay[1])],
          to: [parseInt(initYearList[initYearList.length - 1]), parseInt(toMonthAndDay[0]), parseInt(toMonthAndDay[1])]
        };

        // Set Default View
        let initCurrentYear = initActiveDateRange.to[0];
        let initCurrentMonth = initActiveDateRange.to[1];

        // Find Active Month Range for CURRENT YEAR
        let initCurrentActiveMonthRange = this.getActiveMonthRangeFor(initCurrentYear);

        // Find Active/Inactive Buttons for CURRENT YEAR & MONTH
        let initIsButtonInactive = this.getInactiveButtons(initActiveDateRange, initCurrentYear, initCurrentMonth);

        this.setState(
          {
            yearList: initYearList,
            activeDateRange: initActiveDateRange,
            currentYear: initCurrentYear,
            currentMonth: initCurrentMonth,
            currentActiveMonthRange: initCurrentActiveMonthRange,
            isButtonInactive: initIsButtonInactive
          },
          () => {
            // console.log(this.state);
          }
        );
      } else {
        this.setState({ yearList: null });
        console.log("Error: Empty ReadLog");
      }
    } else {
      this.setState({ yearList: null });
      console.log("Error: 'undefined' yearWiseDateObject");
    }
  };

  getCalendarGridConfig = () => {
    const firstDayOfMonth = new Date(this.state.currentYear, this.state.currentMonth, 1);
    const indexOfDayOfTheWeek_of_FirstDayOfMonth = firstDayOfMonth.getDay();
    const numberOfDaysInMonth = this.getDaysInMonth(firstDayOfMonth);

    const girdColumnCount = 35; // [col:7 x row:5]
    let tempDayCount = 1; // days must start from 1 :)
    let gridConfig = [];
    for (let i = 0; i < girdColumnCount; i++) {
      if (i < indexOfDayOfTheWeek_of_FirstDayOfMonth) {
        gridConfig.push(null);
      } else if (tempDayCount > numberOfDaysInMonth) {
        gridConfig.push(null);
      } else {
        gridConfig.push(tempDayCount);
        tempDayCount++;
      }
    }

    // Check whether the month needs 6 rows ie: [col:7 x row:6]
    if (tempDayCount < numberOfDaysInMonth) {
      for (let h = 0; h < 7; h++) {
        if (tempDayCount > numberOfDaysInMonth) {
          gridConfig.push(null);
        } else {
          gridConfig.push(tempDayCount);
          tempDayCount++;
        }
      }

      //   console.log("Adding a new row to the grid");
    }

    // console.log(firstDayOfMonth);
    // console.log(numberOfDaysInMonth);
    return gridConfig;
  };

  getWeekHeadings = () => {
    return (
      <React.Fragment>
        {this.daysOfWeek.map((day, index) => (
          <div key={`ch-${index}`} className="c-header">
            {day}
          </div>
        ))}
      </React.Fragment>
    );
  };

  buildCurrentCalendarView = () => {
    const calendarGridConfig = this.getCalendarGridConfig();

    // console.log(calendarGridConfig);

    return (
      <React.Fragment>
        {calendarGridConfig.map((day, index) => (
          <div key={`cd-${index}`} className={`calendar-day ${!day ? "cd-faded" : ""} ${this.addTodayClass(day)}`}>
            {day ? (
              <div className="cday-content">
                <span className="meta-span">{day}</span>
                {this.props.yearWiseDateObject[this.state.currentYear][this.state.currentMonth][day]
                  ? this.setDataForCurrentDate(this.state.currentYear, this.state.currentMonth, day)
                  : null}
              </div>
            ) : null}
          </div>
        ))}
      </React.Fragment>
    );
  };

  setDataForCurrentDate = (year, month, day) => {
    let dataForThisDay = this.getDataListForThisDay([year, month, day]);
    // console.log(dataForThisDay);

    let isFirst =
      this.state.activeDateRange.from[0] === year &&
      this.state.activeDateRange.from[1] === month &&
      this.state.activeDateRange.from[2] === day
        ? true
        : false;

    let isLast =
      this.state.activeDateRange.to[0] === year &&
      this.state.activeDateRange.to[1] === month &&
      this.state.activeDateRange.to[2] === day
        ? true
        : false;

    let dataEndPointMetaDescription;
    if (isFirst && isLast) {
      dataEndPointMetaDescription = "(First/Last Record)";
    } else if (isFirst) {
      dataEndPointMetaDescription = "(First Record)";
    } else if (isLast) {
      dataEndPointMetaDescription = "(Last Record)";
    }

    return (
      <React.Fragment>
        {dataEndPointMetaDescription ? <p className={`meta-cdata mc-orange`}>{dataEndPointMetaDescription}</p> : null}
        <div className="calendar-data">
          {dataForThisDay.map((dailyData, dIndex) => (
            <p key={dIndex}>{this.getLocalTimeString(new Date(dailyData.seconds * 1000))}</p>
          ))}
        </div>
      </React.Fragment>
    );
  };

  addTodayClass = day => {
    let today = new Date();
    let isThisYear = this.state.currentYear === today.getFullYear() ? true : false;
    let isThisMonth = this.state.currentMonth === today.getMonth() ? true : false;
    let isThisDay = day === today.getDate() ? true : false;

    if (isThisYear && isThisMonth && isThisDay) {
      //   console.log("Set Today!");
      return "cd-today";
    } else {
      return "";
    }
  };

  // --Calendar Controls--
  handleCalendarControls = event => {
    if (!event.currentTarget.classList.contains("cc-inactive")) {
      const operation = event.currentTarget.id.trim();
      let newMonth = this.state.currentMonth;

      // console.log(operation);
      switch (operation) {
        case "cc-next":
          newMonth++;
          break;
        case "cc-prev":
          newMonth--;
          break;
        default:
          break;
      }

      let tempIsButtonInactive;
      if (newMonth > 11) {
        let newYear = this.state.currentYear + 1;
        let initCurrentActiveMonthRange = this.getActiveMonthRangeFor(newYear);
        tempIsButtonInactive = this.getInactiveButtons(this.state.activeDateRange, newYear, 0);

        this.setState({
          currentYear: newYear,
          currentMonth: 0,
          currentActiveMonthRange: initCurrentActiveMonthRange,
          isButtonInactive: tempIsButtonInactive
        });
      } else if (newMonth < 0) {
        let newYear = this.state.currentYear - 1;
        let initCurrentActiveMonthRange = this.getActiveMonthRangeFor(newYear);
        tempIsButtonInactive = this.getInactiveButtons(this.state.activeDateRange, newYear, 11);

        this.setState({
          currentYear: newYear,
          currentMonth: 11,
          currentActiveMonthRange: initCurrentActiveMonthRange,
          isButtonInactive: tempIsButtonInactive
        });
      } else {
        tempIsButtonInactive = this.getInactiveButtons(this.state.activeDateRange, this.state.currentYear, newMonth);

        this.setState({ currentMonth: newMonth, isButtonInactive: tempIsButtonInactive });
      }
    }
  };

  handleCustomSelectClick = event => {
    let selectType = event.currentTarget.getAttribute("data-select");
    this.setState({ dateSelect: { visible: true, by: selectType } }, () => {
      //   console.log(this.state.dateSelect);
    });
  };

  handleCustomOptionClick = event => {
    if (!event.currentTarget.classList.contains("cso-invalid")) {
      let selectOption = event.currentTarget.getAttribute("data-option");
      selectOption = parseInt(selectOption); // Must be an Int!!
      // console.log(selectOption);

      switch (this.state.dateSelect.by) {
        case "year":
          // Must validate month when changing year arbitrarily
          let validCurrentMonth;
          let tempActiveMonthRange = this.getActiveMonthRangeFor(selectOption);
          if (tempActiveMonthRange.includes(this.state.currentMonth)) {
            validCurrentMonth = this.state.currentMonth;
          } else if (this.state.currentMonth < tempActiveMonthRange[0]) {
            validCurrentMonth = tempActiveMonthRange[0];
          } else {
            validCurrentMonth = tempActiveMonthRange[tempActiveMonthRange.length - 1];
          }
          // Must validate month when changing year arbitrarily

          this.setState({
            currentYear: selectOption,
            currentMonth: validCurrentMonth,
            currentActiveMonthRange: tempActiveMonthRange,
            isButtonInactive: this.getInactiveButtons(this.state.activeDateRange, selectOption, validCurrentMonth),
            dateSelect: {
              visible: true,
              by: "month"
            }
          });
          break;
        case "month":
          this.setState({
            currentMonth: selectOption,
            isButtonInactive: this.getInactiveButtons(this.state.activeDateRange, this.state.currentYear, selectOption),
            dateSelect: {
              visible: false,
              by: null
            }
          });
          break;
        default:
          break;
      }
    }
  };

  getInactiveButtons = (activeDateRange, tempCurrentYear, tempCurrentMonth) => {
    let tempIsButtonInactive = {
      prev: false,
      next: false
    };

    if (activeDateRange.from[0] === tempCurrentYear && activeDateRange.from[1] === tempCurrentMonth) {
      tempIsButtonInactive.prev = true;
    }

    if (activeDateRange.to[0] === tempCurrentYear && activeDateRange.to[1] === tempCurrentMonth) {
      tempIsButtonInactive.next = true;
    }

    return tempIsButtonInactive;
  };

  getCustomSelectOptionsFor = type => {
    switch (type) {
      case "year":
        return (
          <React.Fragment>
            {this.state.yearList.map((year, yIndex) => (
              <div
                key={`y_${yIndex}`}
                className={`cs-option ${this.state.currentYear === parseInt(year) ? "cso-selected" : ""}`}
                data-option={year}
                onClick={this.handleCustomOptionClick}
              >
                <p>{year}</p>
              </div>
            ))}
          </React.Fragment>
        );
      // break;
      case "month":
        return (
          <React.Fragment>
            {this.monthArray.map((month, mIndex) => (
              <div
                key={`m_${mIndex}`}
                className={`cs-option ${this.state.currentMonth === mIndex ? "cso-selected" : ""} ${
                  this.state.currentActiveMonthRange.includes(mIndex) ? "" : "cso-invalid"
                }`}
                data-option={mIndex}
                onClick={this.handleCustomOptionClick}
              >
                <p>{month}</p>
              </div>
            ))}
          </React.Fragment>
        );
      // break;
      default:
        break;
    }
  };

  handleClickOutside = event => {
    if (this.state.dateSelect.visible) {
      let popUpElement = document.getElementById("custom-select-parent-id");

      if (!popUpElement.contains(event.target)) {
        // console.log("click outside!");
        this.setState({ dateSelect: { visible: false, by: null } });
      }
    }
  };

  handleCalDescEndPointClick = event => {
    switch (event.currentTarget.id.split("-")[1]) {
      case "first":
        this.setState({
          currentYear: this.state.activeDateRange.from[0],
          currentMonth: this.state.activeDateRange.from[1],
          isButtonInactive: this.getInactiveButtons(
            this.state.activeDateRange,
            this.state.activeDateRange.from[0],
            this.state.activeDateRange.from[1]
          )
        });
        break;
      case "last":
        this.setState({
          currentYear: this.state.activeDateRange.to[0],
          currentMonth: this.state.activeDateRange.to[1],
          isButtonInactive: this.getInactiveButtons(
            this.state.activeDateRange,
            this.state.activeDateRange.to[0],
            this.state.activeDateRange.to[1]
          )
        });
        break;
      default:
        break;
    }
  };
  // --Calendar Controls--
  //#endregion ----------------------------- CALENDAR FUNCTIONS --------------------------------

  //#region --------------------------------- HELPER FUNCTIONS ---------------------------------
  getActiveEndPointFor = (year, endPoint) => {
    let yearObject = this.props.yearWiseDateObject[year];
    let yearObjectKeys = Object.keys(yearObject);
    let monthObject;
    let monthObjectKeys;

    let activeEndPoints = [];
    switch (endPoint) {
      case "first":
        activeEndPoints.push(yearObjectKeys[0]);

        monthObject = this.props.yearWiseDateObject[year][activeEndPoints[0]];
        monthObjectKeys = Object.keys(monthObject);

        activeEndPoints.push(monthObjectKeys[0]);
        break;
      case "last":
        activeEndPoints.push(yearObjectKeys[yearObjectKeys.length - 1]);

        monthObject = this.props.yearWiseDateObject[year][activeEndPoints[0]];
        monthObjectKeys = Object.keys(monthObject);

        activeEndPoints.push(monthObjectKeys[monthObjectKeys.length - 1]);
        break;
      default:
        break;
    }

    return activeEndPoints;
  };

  getActiveMonthRangeFor = year => {
    let yearObject = this.props.yearWiseDateObject[year];

    let tempList = Object.keys(yearObject);
    let activeMonthRange = [];

    let monthIndexCounter = parseInt(tempList[0]);
    const endPoint = parseInt(tempList[tempList.length - 1]);
    while (monthIndexCounter <= endPoint) {
      activeMonthRange.push(monthIndexCounter);
      monthIndexCounter++;
    }

    // console.log(year);
    // console.log(yearObject);
    // console.log(tempList);
    // console.log(activeMonthRange);
    return activeMonthRange;
  };

  getDataListForThisDay = dateAsAnArray => {
    let dataList = this.props.yearWiseDateObject[dateAsAnArray[0]][dateAsAnArray[1]][dateAsAnArray[2]];

    // console.log(monthArray);
    return dataList;
  };

  getEndPointDateString = type => {
    let dataList;
    switch (type) {
      case "first":
        dataList = this.getDataListForThisDay(this.state.activeDateRange.from);
        return new Date(dataList[0].seconds * 1000).toLocaleString();
      // break;
      case "last":
        dataList = this.getDataListForThisDay(this.state.activeDateRange.to);
        return new Date(dataList[dataList.length - 1].seconds * 1000).toLocaleString();
      // break;
      default:
        break;
    }
  };

  getHumanReadableMonth = index => {
    return this.monthArray[index];
  };

  getDayOfTheWeek = index => {
    return this.daysOfWeek[index];
  };

  getDaysInMonth = dateObject => {
    return new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0).getDate(); // == to Last Date
  };

  getLocalTimeString = date => {
    // console.log(date);
    return date.toLocaleString().split(",")[1];
  };
  //#endregion ------------------------------ HELPER FUNCTIONS ---------------------------------

  componentDidMount() {
    // console.log(this.props.yearWiseDateObject);
    this.initCalendarView(this.props.yearWiseDateObject);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.yearWiseDateObject !== this.props.yearWiseDateObject) {
      this.initCalendarView(this.props.yearWiseDateObject);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.yearList ? (
          <div className="calendar-wrapper">
            {/* Calendar Toolbar */}
            <div className="calendar-toolbar">
              <div className="ct-label-row">
                {this.props.calendarToolbarLabel ? (
                  <React.Fragment>
                    <span className="ct-label-property">{this.props.calendarToolbarLabel.property}</span>
                    <span className="ct-label-value">{this.props.calendarToolbarLabel.value}</span>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="calendar-controls" id="custom-select-parent-id">
                {/* Prev Button */}
                <div
                  id="cc-prev"
                  className={`cc-button ${this.state.isButtonInactive.prev ? "cc-inactive" : ""}`}
                  onClick={this.handleCalendarControls}
                >
                  <FontAwesomeIcon icon="chevron-left" />
                </div>
                {/* Custom Select */}
                <div className="cc-select-wrapper">
                  <div className="cc-select" data-select="year" onClick={this.handleCustomSelectClick}>
                    <p>{this.state.currentYear}</p>
                  </div>
                  <div className="cc-select" data-select="month" onClick={this.handleCustomSelectClick}>
                    <p>{this.getHumanReadableMonth(this.state.currentMonth)}</p>
                  </div>
                </div>
                {/* Custom Select Options */}
                {this.state.dateSelect.visible ? (
                  <div
                    className={`cc-select-option-wrapper ${
                      this.state.dateSelect.by === "month" ? "select-wrapper-grid" : ""
                    }`}
                  >
                    {this.getCustomSelectOptionsFor(this.state.dateSelect.by)}
                  </div>
                ) : null}
                {/* Next Button */}
                <div
                  id="cc-next"
                  className={`cc-button ${this.state.isButtonInactive.next ? "cc-inactive" : ""}`}
                  onClick={this.handleCalendarControls}
                >
                  <FontAwesomeIcon icon="chevron-right" />
                </div>
              </div>
            </div>
            {/* Calendar Body */}
            <div className="calendar-body">
              {this.getWeekHeadings()}
              {this.buildCurrentCalendarView()}
            </div>
            {/* Calendar Description */}
            <div className="calendar-description">
              {this.props.footerLabel ? (
                <div className="cdesc-wrapper">
                  <span className="cdesc-row">
                    <span className="cdesc-property">{this.props.footerLabel.property}</span>
                    <span className="cdesc-value cdesc-mono">{this.props.footerLabel.value}</span>
                  </span>
                </div>
              ) : null}
              <div className="cdesc-wrapper">
                <div className="cdesc-row">
                  <span className="cdesc-property">First Record:</span>
                  <span
                    id="endpoint-first"
                    className="cdesc-value cdesc-link"
                    onClick={this.handleCalDescEndPointClick}
                  >
                    {this.getEndPointDateString("first")}
                  </span>
                </div>
              </div>
              <div className="cdesc-wrapper">
                <div className="cdesc-row">
                  <span className="cdesc-property">Last Record:</span>
                  <span id="endpoint-last" className="cdesc-value cdesc-link" onClick={this.handleCalDescEndPointClick}>
                    {this.getEndPointDateString("last")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Calendar;
