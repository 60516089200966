//#region ------ DATA PROCESSORS ------
export const calculateTotalDatewiseDataSetValueFor = (sectionType, dateFrom, dateTo, sentinelOverviewCollection) => {
  let totalDatewiseDataSetValue = 0;
  let loopEndPointReached = false;

  let tempYear = dateFrom.getFullYear();
  let tempMonth = dateFrom.getMonth();
  let tempDay = dateFrom.getDate();

  let endYear = dateTo.getFullYear();
  let endMonth = dateTo.getMonth();
  let endDay = dateTo.getDate();

  // console.log("###########################");
  // console.log("---------END DATE----------");
  // console.log(endYear, endMonth, endDay);
  // console.log("---------END DATE----------");

  // let temp_loop_counter = 0;  // ---- USE WHEN TESTING
  while (!loopEndPointReached) {
    if (
      sentinelOverviewCollection[tempYear] &&
      sentinelOverviewCollection[tempYear][tempMonth] &&
      sentinelOverviewCollection[tempYear][tempMonth][tempDay] &&
      sentinelOverviewCollection[tempYear][tempMonth][tempDay][sectionType]
    ) {
      totalDatewiseDataSetValue += sentinelOverviewCollection[tempYear][tempMonth][tempDay][sectionType];
    }

    if (tempYear === endYear && tempMonth === endMonth && tempDay === endDay) {
      loopEndPointReached = true;

      // console.log("Count Ended At: ", tempYear, tempMonth, tempDay);
      // console.log("###########################");
    } else {
      let lastDayOfMonth = getDaysInMonth(new Date(`${tempYear}-${tempMonth + 1}-1`));
      // console.log("lastDayOfMonth:", lastDayOfMonth);
      // console.log(tempYear, tempMonth, tempDay);

      //increase Date
      tempDay++;
      if (tempDay > lastDayOfMonth) {
        tempDay = 1;
        tempMonth++;

        if (tempMonth > 11) {
          tempMonth = 0;
          tempYear++;
        }
      }
    }

    // ---- USE WHEN TESTING
    //   temp_loop_counter++;
    //   if (temp_loop_counter >= 1000) {
    //     loopEndPointReached = true;
    //   }
    // ---- USE WHEN TESTING
  }

  return totalDatewiseDataSetValue;
};
//#endregion --- DATA PROCESSORS ------

//#region ------ DATE FUNCTIONS ------
export const getDaysInMonth = dateObject => {
  return new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0).getDate(); // == to Last Date
};
//#endregion --- DATE FUNCTIONS ------
