// Add Highlevel Operational States here
const operationalStates = {
  sidebar: {
    sentinelOverview: "sentinel-overview",
    records: "records",
    keys: "keys"
  }
};

// Set Initial App State
export const INIT_CONFIG = {
  appOPState: operationalStates.sidebar.sentinelOverview
  // appOPState: operationalStates.sidebar.records
  // appOPState: operationalStates.sidebar.keys
};

export const DATABASE_STARTING_POINT_FALLBACK = "2020-03-01";

// Add Sidebar Menu Items here along with its corresponding operatioanlState
export const SIDEBAR_NAV_ITEMS = [
  {
    navID: 1,
    itemLabel: "Overview",
    navOPState: operationalStates.sidebar.sentinelOverview
  },
  {
    navID: 2,
    itemLabel: "Activated PCs",
    navOPState: operationalStates.sidebar.records
  },
  {
    navID: 3,
    itemLabel: "License Keys",
    navOPState: operationalStates.sidebar.keys
  }
];

// key should be the corresponding records collection property name
export const RECORDS_TABLE_HEADINGS = [
  {
    key: "operations",
    label: " ",
    sorting: false
  },
  {
    key: "createdAt",
    label: "Created At",
    sorting: true
  },
  {
    key: "lastReadAt",
    label: "Last Read At",
    sorting: true
  },
  {
    key: "hardwareToken",
    label: "💻 Hardware Token",
    sorting: false
  },
  {
    key: "licenseKey",
    label: "🔑 License Key",
    sorting: false
  }
  // {
  //   key: "status",
  //   label: "Status",
  //   sorting: false
  // }
];

// key should be the corresponding keys collection property name
export const KEYS_TABLE_HEADINGS = [
  {
    key: "operations",
    label: " ",
    sorting: false
  },
  {
    key: "createdAt",
    label: "Created At",
    sorting: true
  },
  {
    key: "modifiedAt",
    label: "Modified At",
    sorting: true
  },
  {
    key: "docID",
    label: "🔑 License Key",
    sorting: false
  },
  {
    key: "flags",
    label: "Flags",
    sorting: false
  },
  {
    key: "status",
    label: "Status",
    sorting: false
  }
];

export const KEY_INFO_NAV = [
  { key: "overview", label: "Overview" },
  { key: "device-history", label: "Device History" },
  { key: "timeline", label: "Timeline" }
];
