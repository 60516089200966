import React from "react";

import "./add-key.style.scss";
import { ReactComponent as Spinner } from "../../images/Spinner.svg";

import { auth } from "../../firebase/firebase.utils";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AddKey extends React.Component {
  constructor() {
    super();

    this.state = {
      licenseKey: "",
      lqt: null,
      reason: null,
      remarks: null,
      apiEndPoint: this.DEFAULT_API_ENDPOINT,
      popup: false
    };
  }

  DEFAULT_API_ENDPOINT = "create";

  resetAddKeyState = () => {
    this.setState({ licenseKey: "", lqt: null, reason: null, remarks: null, apiEndPoint: "create", popup: false });
  };

  addLicenseKey = () => {
    this.setState({ popup: true });

    swal({
      title: "Add License Key",
      content: this.getSWALAddKey(),
      buttons: {
        cancel: true,
        confirm: {
          text: "Add Key",
          className: "action-btn general-btn"
        }
      }
    }).then(value => {
      if (value) {
        let licenseKey = this.state.licenseKey.trim();
        let licenseQuota = Number(this.state.lqt);
        let reason = this.state.reason;
        let remarks = this.state.remarks;
        let apiEndPoint = this.state.apiEndPoint;

        if (licenseKey === "") {
          swal(`License Key cannot be empty!`, {
            icon: "error"
          });
        } else if (licenseKey.length <= 10) {
          swal(`Invalid License Key Length!`, {
            icon: "error"
          });
        } else if (!licenseQuota || licenseQuota <= 0 || !Number.isInteger(licenseQuota)) {
          swal(`License Quota should be a valid Number!`, {
            icon: "error"
          });
        } else if (!reason || reason.length < 10) {
          swal(`Reason must contain at least 10 characters!`, {
            icon: "error"
          });
        } else {
          // ---------
          swal({
            content: <Spinner />,
            text: "Processing...",
            button: false,
            closeOnClickOutside: false,
            closeOnEsc: false
          });
          this.initCreateKeyAPICall(licenseKey, licenseQuota, reason, remarks, apiEndPoint);
          // ---------F
        }
      }

      this.resetAddKeyState();
    });
  };

  getSWALAddKey = () => {
    return (
      <div>
        <div className="custom-field">
          <p className="custom-label">License Key:</p>
          <input
            id="swal-license-key"
            className="custom-input"
            placeholder="at least 10 characters"
            name="license-key"
            onChange={this.handleSWALInputChange}
          />
        </div>
        <div className="custom-field">
          <p>License Quota:</p>
          <input name="license-quota" onChange={this.handleSWALInputChange} />
        </div>

        <div className="custom-field">
          <p className="inline-label">Flags:</p>
          <div id="id-custom-check-wrapper" className="custom-check-wrapper">
            <div data-value="block" className="custom-check-element cce-block" onClick={this.handleSWALCustomCheck}>
              blocked
            </div>
            <div data-value="refund" className="custom-check-element cce-refund" onClick={this.handleSWALCustomCheck}>
              refunded
            </div>
          </div>
        </div>

        <div className="ka-reason">
          <p>Reason:</p>
          <input name="reason" placeholder="at least 10 characters" onChange={this.handleSWALInputChange} />
        </div>

        <div className="ka-remarks">
          <p>Remarks:</p>
          <textarea name="remarks" onChange={this.handleSWALInputChange} />
        </div>
      </div>
    );
  };

  handleSWALInputChange = event => {
    switch (event.currentTarget.name) {
      case "license-key":
        this.setState({ licenseKey: event.currentTarget.value });
        break;
      case "license-quota":
        this.setState({ lqt: event.currentTarget.value });
        break;
      case "reason":
        this.setState({ reason: event.currentTarget.value });
        break;
      case "remarks":
        this.setState({ remarks: event.currentTarget.value });
        break;
      default:
        break;
    }
  };

  handleSWALCustomCheck = event => {
    let targetType = event.currentTarget.getAttribute("data-value");
    let customCheckWrapper = document.getElementById("id-custom-check-wrapper");
    // console.log(targetType);

    if (event.currentTarget.classList.contains("cce-checked")) {
      if (targetType === "block" && this.state.apiEndPoint === "refund") {
        for (let i = 0; i < customCheckWrapper.children.length; i++) {
          // console.log(customCheckWrapper.children[i].classList);
          customCheckWrapper.children[i].classList.remove("cce-checked");
        }
        event.currentTarget.classList.add("cce-checked");
        this.setState({ apiEndPoint: targetType });
      } else {
        for (let i = 0; i < customCheckWrapper.children.length; i++) {
          // console.log(customCheckWrapper.children[i].classList);
          customCheckWrapper.children[i].classList.remove("cce-checked");
        }
        this.setState({ apiEndPoint: this.DEFAULT_API_ENDPOINT });
      }
    } else {
      switch (targetType) {
        case "refund":
          for (let i = 0; i < customCheckWrapper.children.length; i++) {
            // console.log(customCheckWrapper.children[i].classList);
            customCheckWrapper.children[i].classList.add("cce-checked");
          }
          this.setState({ apiEndPoint: "refund" });
          break;
        case "block":
          event.currentTarget.classList.add("cce-checked");
          this.setState({ apiEndPoint: targetType });
          break;
        default:
          break;
      }
    }
  };

  initCreateKeyAPICall = async (licenseKey, licenseQuota, reason, remarks, apiEndPoint = this.DEFAULT_API_ENDPOINT) => {
    let apiPath = `https://license.bestxsoftware.com/api/${apiEndPoint}/`;
    let currentUser = auth.currentUser;
    let authToken = await currentUser.getIdToken();

    let successMessage = (
      <p className="swal-custom-label">
        <span className="cl-em">{licenseKey}</span>
        <span>license key added successfully!</span>
      </p>
    );

    let requestBody = {
      key: licenseKey,
      lqt: licenseQuota,
      user: currentUser.displayName,
      email: currentUser.email,
      reason: reason,
      remarks: remarks
    };

    if (apiEndPoint !== this.DEFAULT_API_ENDPOINT) {
      requestBody.create = true;
    }

    let requestConfig = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(requestBody)
    };

    // console.log(requestBody);
    // console.log(requestConfig);

    fetch(apiPath, requestConfig)
      .then(response => {
        // console.log(response);
        if (response.status === 200) {
          this.props.updateKeysCollection();

          setTimeout(() => {
            swal({
              content: successMessage,
              icon: "success"
            });
          }, 2000);
        } else {
          response
            .json()
            .then(data => {
              // console.log(data);
              if (data.status === 412) {
                swal({
                  title: `${data.name}`,
                  text: data.message,
                  icon: "warning"
                });
              } else {
                swal({
                  title: `Error ${response.status}`,
                  text: data.message,
                  icon: "error"
                });
              }
            })
            .catch(error => {
              swal({
                title: `Error ${response.status}`,
                text: error.message,
                icon: "error"
              });
            });
        }
      })
      .catch(error => {
        swal({
          title: `Failed communication with the API!`,
          text: error.message,
          icon: "error"
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.state.popup) {
      this.resetAddKeyState();
      setTimeout(() => {
        if (document.getElementById("swal-license-key")) {
          document.getElementById("swal-license-key").focus();
        }
      }, 100);
    }
  }

  render() {
    return (
      <div className="add-key-button" title="Add Key" onClick={this.addLicenseKey}>
        <FontAwesomeIcon icon="plus" />
      </div>
    );
  }
}

export default AddKey;
