import React from "react";

import "./key-overview.style.scss";
import { ReactComponent as Spinner } from "../../../images/Spinner.svg";

import { auth } from "../../../firebase/firebase.utils";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class KeyOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyData: this.props.keyData,
      transferredMachines: [],
      leakedEvents: null,
      activeMiniPopUp: null,
      keyActionState: {
        init: false,
        reason: "",
        remarks: "",
        transferCount: null
      }
    };
  }

  //#region  ------------------------------------------- KEY ACTIONS ---------------------------------------------------
  // ---
  // ---
  // ---
  // ---

  handleActionButtons = event => {
    if (!event.currentTarget.classList.contains("innactive")) {
      let action = event.currentTarget.id;
      // console.log(action);

      // Activate keyAction
      let tempState = this.state.keyActionState;
      tempState.init = true;
      this.setState({ keyActionState: tempState });

      switch (action) {
        case "block-key":
          this.blockKey();
          break;
        case "unblock-key":
          this.unblockKey();
          break;
        case "refund-key":
          this.refundKey();
          break;
        case "transfer-key":
          this.transferKey();
          break;
        default:
          break;
      }
    }
  };

  blockKey = () => {
    let alertConfig = {
      title: "Key Action: Block Key",
      confirm: {
        text: "Block",
        className: "action-btn block-btn"
      }
    };

    let successMessage = (
      <p className="swal-custom-label">
        <span className="cl-em">{this.state.keyData.licenseKey}</span>
        <span>is Blocked!</span>
      </p>
    );

    this.initKeyActionWithReason("block", successMessage, alertConfig);
  };

  unblockKey = () => {
    let alertConfig = {
      title: "Key Action: Unblock Key",
      confirm: {
        text: "Unblock",
        className: "action-btn unblock-btn"
      }
    };

    let successMessage = (
      <p className="swal-custom-label">
        <span className="cl-em">{this.state.keyData.licenseKey}</span>
        <span>is Unblocked!</span>
      </p>
    );

    this.initKeyActionWithReason("unblock", successMessage, alertConfig);
  };

  refundKey = async () => {
    let alertConfig = {
      title: "Key Action: Grant Refund",
      confirm: {
        text: "Refund",
        className: "action-btn refund-btn"
      }
    };

    let successMessage = (
      <p className="swal-custom-label">
        <span className="cl-em">{this.state.keyData.licenseKey}</span>
        <span>was Refunded!</span>
      </p>
    );

    this.initKeyActionWithReason("refund", successMessage, alertConfig);
  };

  transferKey = () => {
    swal({
      title: "Key Action: Grant Transfer",
      content: this.getSWALTransfer(),
      buttons: {
        cancel: true,
        confirm: {
          text: "Transfer",
          className: "action-btn transfer-btn"
        }
      }
    }).then(value => {
      if (value) {
        let availableTransfers = this.state.keyData.transferQuota - this.state.keyData.transferCount;
        let transferCount = Number(this.state.keyActionState.transferCount);
        let remarks = this.state.keyActionState.remarks !== "" ? this.state.keyActionState.remarks : null;

        if (!transferCount || transferCount <= 0 || !Number.isInteger(transferCount)) {
          swal(`Transfer count must be a valid number!`, {
            icon: "error"
          });
        } else if (transferCount > availableTransfers) {
          swal(`Only ${availableTransfers} transfers available!`, {
            icon: "error"
          });
        } else if (transferCount > this.state.keyData.activeQuota) {
          swal(`You can only transfer ${this.state.keyData.activeQuota} device(s) at a time!`, {
            icon: "error"
          });
        } else {
          // ---------
          let successMessage = (
            <p className="swal-custom-label">
              <span>Transfer granted for </span>
              <span className="cl-em">{this.state.keyData.licenseKey}</span>
            </p>
          );
          swal({
            content: <Spinner />,
            text: "Processing...",
            button: false,
            closeOnClickOutside: false,
            closeOnEsc: false
          });
          this.initAPICall("transfer", successMessage, null, remarks, transferCount);
          // ---------
        }
      }

      this.resetKeyActionState();
    });
  };

  initKeyActionWithReason = (keyAction, successMessage, alertConfig) => {
    swal({
      title: alertConfig.title,
      buttons: {
        cancel: true,
        confirm: alertConfig.confirm
      },
      content: this.getSWALReasonAndRemarks()
    }).then(action => {
      //   console.log(action);

      if (action) {
        // console.log(this.state.keyActionState);
        if (this.state.keyActionState.reason.length < 10) {
          swal(`Reason must contain at least 10 characters!`, {
            icon: "error"
          });
        } else {
          let remarks = this.state.keyActionState.remarks !== "" ? this.state.keyActionState.remarks : null;

          swal({
            content: <Spinner />,
            text: "Processing...",
            button: false,
            closeOnClickOutside: false,
            closeOnEsc: false
          });

          this.initAPICall(keyAction, successMessage, this.state.keyActionState.reason, remarks);
        }
      }

      this.resetKeyActionState();
    });
  };

  // -------- SWEETALERT COMPONENTS --------
  getSWALReasonAndRemarks = () => {
    return (
      <div>
        <div className="ka-reason">
          <p>Reason:</p>
          <input
            id="swal-reason"
            tabIndex="1"
            name="reason"
            placeholder="at least 10 characters"
            onChange={this.handleKeyActionInputChange}
            autoFocus={true}
          />
        </div>
        <div className="ka-remarks">
          <p>Remarks:</p>
          <textarea tabIndex="2" name="remarks" onChange={this.handleKeyActionInputChange} />
        </div>
      </div>
    );
  };

  getSWALTransfer = () => {
    return (
      <div>
        <div className="custom-field">
          <p>Transfer Count:</p>
          <input
            id="swal-transfer-count"
            // pattern={``}
            name="transfer-count"
            onChange={this.handleKeyActionInputChange}
          />
        </div>
        <div className="ka-remarks">
          <p>Remarks:</p>
          <textarea name="remarks" onChange={this.handleKeyActionInputChange} />
        </div>
      </div>
    );
  };

  handleKeyActionInputChange = event => {
    let newState = this.state.keyActionState;

    switch (event.currentTarget.name) {
      case "reason":
        newState.reason = event.currentTarget.value;
        break;
      case "remarks":
        newState.remarks = event.currentTarget.value;
        break;
      case "transfer-count":
        newState.transferCount = event.currentTarget.value;
        break;
      default:
        break;
    }

    this.setState({ keyActionState: newState }, () => {
      //   console.log(this.state.keyActionState);
    });
  };

  resetKeyActionState = () => {
    this.setState({ keyActionState: { init: false, reason: "", remarks: "", transferCount: null } });
  };
  // -------- SWEETALERT COMPONENTS --------

  // ---
  // ---
  // ---
  // ---
  //#endregion ------------------------------------------- KEY ACTIONS ---------------------------------------------------

  //#region -------------------------------------------- API CALL -----------------------------------------------------
  // ---
  // ---
  // ---
  // ---

  initAPICall = async (keyAction, successMessage, reason = null, remarks = null, transferCount = null) => {
    let apiPath = `https://license.bestxsoftware.com/api/${keyAction.toLowerCase()}/`;
    let currentUser = auth.currentUser;
    let authToken = await currentUser.getIdToken();

    let requestBody = {
      key: this.state.keyData.licenseKey,
      user: currentUser.displayName,
      email: currentUser.email,
      remarks: remarks
    };

    if (reason) {
      requestBody.reason = reason;
    }

    if (transferCount) {
      requestBody.transferCount = transferCount;
    }

    // console.log(requestBody);

    // -- TEST FOR API ERRORS
    // requestBody = {
    //     breakAPI: true
    // };
    // -- TEST FOR API ERRORS

    let requestConfig = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(requestBody)
    };

    fetch(apiPath, requestConfig)
      .then(response => {
        // console.log(response);
        if (response.status === 200) {
          try {
            this.props.updateKeysCollection(this.state.keyData.licenseKey);
          } catch (error) {
            console.log(error);
          }

          setTimeout(() => {
            swal({
              content: successMessage,
              icon: "success"
            });
          }, 2000);
        } else {
          response
            .json()
            .then(data => {
              // console.log(data);
              if (data.status === 412) {
                swal({
                  title: `${data.name}`,
                  text: data.message,
                  icon: "warning"
                });
              } else {
                swal({
                  title: `Error ${response.status}`,
                  text: data.message,
                  icon: "error"
                });
              }
            })
            .catch(error => {
              swal({
                title: `Error ${response.status}`,
                text: error.message,
                icon: "error"
              });
            });
        }
      })
      .catch(error => {
        swal({
          title: `Failed communication with the API!`,
          text: error.message,
          icon: "error"
        });
      });
  };

  // ---
  // ---
  // ---
  // ---
  //#endregion -------------------------------------------- API CALL -----------------------------------------------------

  //#region -------------------------------------- OVERVIEW INFO ITEMS -----------------------------------------------
  // ---
  // ---
  // ---
  // ---

  //#region General Info
  //--
  getGeneralInfo = () => {
    return (
      <React.Fragment>
        <h4 className="ib-heading">General Info</h4>
        <div className="ib-body">
          {/* FLAGS */}
          <div className="ib-group">
            <div className="ib-row">
              <h5 className="ib-property">Flags :</h5>
              <div className="ib-value">{this.getFlagRow()}</div>
            </div>
          </div>
          {/* FLAGS */}

          {/* CREATED AT */}
          <div className="ib-group">
            <div className="ib-row">
              <h5 className="ib-property">Created At :</h5>
              <div className="ib-value">{this.getDate(this.state.keyData.createdAt)}</div>
            </div>
            {this.state.keyData.isManuallyCreated ? (
              <div className="ib-row">
                <h5 className="ib-property">Created By :</h5>
                <div className="ib-value">{`${this.state.keyData.createdBy.name} (${this.state.keyData.createdBy.email})`}</div>
              </div>
            ) : null}
          </div>
          {/* CREATED AT */}

          {/* ACTIVE INFO */}
          <div className="ib-group highlighted">
            <div className="ib-row">
              <h5 className="ib-property">License Quota :</h5>
              <div className="ib-value">{this.state.keyData.activeQuota}</div>
            </div>

            <div className="ib-row">
              <h5 className="ib-property">{`Active PC(s) :`}</h5>
              <div className="ib-value">
                <span>{`${this.state.keyData.isBlocked ? "0" : this.state.keyData.activeList.length}`}</span>

                {/* isTransferring */}
                {this.state.keyData.isTransferring && !this.state.keyData.isBlocked ? (
                  <FontAwesomeIcon
                    title="transfer in progress..."
                    icon="exchange-alt"
                    color="#09a3cc"
                    className="tiny-icon"
                  />
                ) : null}
                {/* isTransferring */}
              </div>
            </div>
          </div>
          {/* ACTIVE INFO */}

          {/* TRANSFER  INFO */}
          <div className="ib-group">
            <div className="ib-row">
              <h5 className="ib-property">Transfer Quota :</h5>
              <div className="ib-value">{this.state.keyData.transferQuota}</div>
            </div>

            <div className="ib-row">
              <h5 className="ib-property">Available Transfers :</h5>
              <div className="ib-value">
                <span>{this.getAvailableTransfers()}</span>

                {/* isTransferring */}
                {this.state.keyData.isTransferring ? (
                  <FontAwesomeIcon
                    title={`${this.state.keyData.isBlocked ? "incomplete transfer!" : "transfer in progress..."}`}
                    icon={`${this.state.keyData.isBlocked ? "exclamation-triangle" : "exchange-alt"}`}
                    color={`${this.state.keyData.isBlocked ? "#f5b01c" : "#09a3cc"}`}
                    className="tiny-icon"
                  />
                ) : null}
                {/* isTransferring */}
              </div>
            </div>

            {/* {this.state.transferredMachines.length > 0 ? (
              <div className="ib-row">
                <h5 className="ib-property">Transferred Machine(s) :</h5>
                <div className="ib-value ib-value-mono">{this.getTransferredMachines()}</div>
              </div>
            ) : null} */}
          </div>
          {/* TRANSFER  INFO */}

          {/* LEAKED COUNT */}
          {this.state.keyData.isLeaked ? (
            <div className="ib-group">
              <div className="ib-row">
                <h5 className="ib-property">Leaked Count :</h5>
                <div className="ib-value">{this.state.keyData.leakedCount}</div>
              </div>
            </div>
          ) : null}
          {/* LEAKED COUNT */}

          {/* ADMIN ACTIONS for this KEY */}
          <div className="ib-group colorless">
            <div className="ib-row align-right">{this.getActionButtons()}</div>
          </div>
          {/* ADMIN ACTIONS for this KEY */}
        </div>
      </React.Fragment>
    );
  };

  // TODO Finalize this after figuring out the transfer process :)
  getAvailableTransfers = () => {
    if (this.state.keyData.isTransferring) {
      let totalDevices;
      let totalTransfers;

      if (this.state.keyData.activatedList.length < this.state.keyData.activeQuota) {
        totalDevices = this.state.keyData.transferQuota;
        totalTransfers = this.state.keyData.transfersAllowed;
      } else {
        totalDevices = this.state.keyData.transferQuota + this.state.keyData.activeQuota;
        totalTransfers = this.state.keyData.transfersAllowed + this.state.keyData.activatedList.length;
      }

      let availableTransfers = totalDevices - totalTransfers;
      // console.log(totalDevices);
      // console.log(totalTransfers);
      // console.log(availableTransfers);
      return availableTransfers;
    } else {
      return this.state.keyData.transferQuota - this.state.keyData.transferCount;
    }
  };

  // getTransferredMachines = () => {
  //   if (this.state.transferredMachines.length > 0) {
  //     return (
  //       <React.Fragment>
  //         {this.state.transferredMachines.map((pc, idx) => (
  //           <span key={idx} className={`tf-pc new-tf`}>
  //             {pc}
  //           </span>
  //         ))}
  //       </React.Fragment>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  getFlagRow = () => {
    return (
      <React.Fragment>
        {this.state.keyData.isActivated ? <p className="td-flags activated">activated</p> : null}

        {/* {this.state.keyData.isModified ? <p className="td-flags modified">modified</p> : null} */}

        {this.state.keyData.isModified ? <p className="td-flags modified">modified</p> : null}

        {this.state.keyData.isBlocked ? <p className="td-flags blocked">blocked</p> : null}

        {this.state.keyData.isRefunded ? <p className="td-flags refunded">refunded</p> : null}

        {this.state.keyData.isTransferred ? <p className="td-flags transferred">transferred</p> : null}

        {this.state.keyData.isLeaked ? <p className="td-flags leaked">leaked</p> : null}
      </React.Fragment>
    );
  };

  getActionButtons = () => {
    return (
      <React.Fragment>
        {/* Block Button */}
        <div
          id="block-key"
          className={`action-btn ${this.state.keyData.isBlocked ? "hide-btn" : "general-btn"}`}
          onClick={this.handleActionButtons}
        >
          Block Key
        </div>

        {/* Unblock Button */}
        <div
          id="unblock-key"
          className={`action-btn ${this.getUnblockButtonClass()}`}
          onClick={this.handleActionButtons}
        >
          Unblock Key
        </div>

        {/* Refund Button */}
        <div
          id="refund-key"
          className={`action-btn ${this.state.keyData.isRefunded ? "hide-btn" : "general-btn"}`}
          onClick={this.handleActionButtons}
        >
          Grant Refund
        </div>

        {/* Transfer Button */}
        <div
          id="transfer-key"
          className={`action-btn ${this.getTransferButtonClass()}`}
          onClick={this.handleActionButtons}
        >
          Grant Transfer
        </div>
      </React.Fragment>
    );
  };

  getUnblockButtonClass = () => {
    if (this.state.keyData.isBlocked) {
      if (this.state.keyData.isRefunded) {
        return "hide-btn";
      } else {
        return "general-btn";
      }
    } else {
      return "hide-btn";
    }
  };

  getTransferButtonClass = () => {
    if (this.state.keyData.isRefunded || this.state.keyData.transferCount === this.state.keyData.transferQuota) {
      return "hide-btn";
    } else if (this.state.keyData.isBlocked || this.state.keyData.activeList.length === 0) {
      return "inactive";
    } else {
      return "general-btn";
    }
  };
  //--
  //#endregion General Info

  getActiveInfo = () => {
    return (
      <React.Fragment>
        <h4 className="ib-heading">{`${this.state.keyData.isBlocked ? "Last " : ""}Active PC(s)`}</h4>
        <div className="ib-body">
          {/* START IB-ROWS */}

          {this.state.keyData.activeList.map((pc, index) => (
            <div key={`ib-gwi-active-${index}`} className="ib-group-with-icon">
              <div className="ib-group-icon ib-gi-active">
                <FontAwesomeIcon icon="desktop" />
              </div>
              <div className="ib-group">
                <div className="ib-row">
                  <h5 className="ib-property">Hardware ID :</h5>
                  <div className="ib-value ib-value-mono">{pc.hwt}</div>
                </div>
                <div className="ib-row">
                  <h5 className="ib-property">Last Read At :</h5>
                  <div className="ib-value">{this.getDate(pc.lastReadAt)}</div>
                </div>
                <div className="ib-row add-margin-top">
                  {/* <h5 className="ib-property">Read Log :</h5> */}
                  <div className="ib-value">
                    <span
                      className="action-btn general-btn load-readlog-btn"
                      data-record-info={`${pc.recordId}:${pc.hwt}`}
                      onClick={this.initReadlogView}
                      title="Show Readlog"
                    >
                      <FontAwesomeIcon icon="feather-alt" />
                      Readlog
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* END IB-ROWS */}
        </div>
      </React.Fragment>
    );
  };

  initReadlogView = event => {
    let recordInfo = event.currentTarget.getAttribute("data-record-info").split(":");

    let hwObject = {
      recordID: recordInfo[0],
      hwt: recordInfo[1]
    };

    // console.log(hwObject);
    this.props.setShowReadlogForState(hwObject);
  };

  // ---
  // ---
  // ---
  // ---
  //#endregion ----------------------------------- OVERVIEW INFO ITEMS -----------------------------------------------

  //#region ---------------------------------------- HELPER FUNCTIONS ------------------------------------------------
  getDate = dateO => {
    let date = new Date(dateO.seconds * 1000);
    // console.log(date);
    return date.toLocaleString();
  };
  //#endregion ------------------------------------- HELPER FUNCTIONS ------------------------------------------------

  //#region ----------------------------------------- PRE-PROCESSING -------------------------------------------------
  // Add all new pre-processors into this function
  // runPreProcessors = () => {
  //   this.createTransferredMachineList();
  // };

  // createTransferredMachineList = () => {
  //   let oldPCList = [];
  //   let finalNewPCList = [];
  //   let tfIndex = this.props.keyData.transferList.length > 0 ? this.props.keyData.transferList.length : null;

  //   if (tfIndex) {
  //     for (let i = 0; i < tfIndex; i++) {
  //       if (this.state.keyData.transferList[i]) {
  //         this.state.keyData.transferList[i].oldList.forEach(oldPC => {
  //           if (!oldPCList.includes(oldPC)) {
  //             oldPCList.push(oldPC);
  //           }
  //         });

  //         this.state.keyData.transferList[i].newList.forEach(newPC => {
  //           if (!oldPCList.includes(newPC) && !finalNewPCList.includes(newPC)) {
  //             finalNewPCList.push(newPC);
  //           }
  //         });
  //       }
  //     }

  //     this.setState({ transferredMachines: finalNewPCList }, () => {
  //       // console.log(this.state.transferredMachines);
  //     });
  //   }
  // };
  //#endregion -------------------------------------- PRE-PROCESSING -------------------------------------------------

  componentDidMount() {
    // this.runPreProcessors();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keyData !== this.props.keyData) {
      this.setState({ keyData: this.props.keyData }, () => {
        // console.log(this.state.keyData);
        // this.runPreProcessors();
      });
    }

    if (this.state.keyActionState.init) {
      this.resetKeyActionState();
      setTimeout(() => {
        if (document.getElementById("swal-reason")) {
          document.getElementById("swal-reason").focus();
        }
        if (document.getElementById("swal-transfer-count")) {
          document.getElementById("swal-transfer-count").focus();
        }
      }, 100);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="info-block">{this.getGeneralInfo()}</div>
        {this.state.keyData.activeList.length > 0 ? (
          <div className="info-block">{this.getActiveInfo()}</div>
        ) : (
          <div className="info-block">
            <div className="ib-body">
              <div className="ib-group colorless">
                <p className="ib-faded-label">
                  <FontAwesomeIcon icon="desktop" />
                </p>
                <p className="ib-faded-label">{`No Active PC(s)`}</p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default KeyOverview;
