import React from "react";

import "./pagination.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: this.props.paginationDetails.rowsPerPage
    };
  }

  handleRowsPerPageSelect = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.changeRowsPerPageState(event.target.value);
  };

  pageControlHandler = event => {
    if (!event.currentTarget.classList.contains("control-icon-inactive")) {
      // console.log(event.currentTarget.id);
      this.props.changeCurrentPageState(event.currentTarget.id);
    }
  };

  getPageCount = () => {
    if (this.props.collectionMetaData) {
      let currentDocRange;
      let leftOfRange;
      let rightOfRange;

      if (this.props.paginationDetails.totalPages === 1) {
        if (this.props.collectionMetaData.currentDocCount === 0) {
          leftOfRange = 0;
          rightOfRange = 0;
        } else {
          leftOfRange = 1;
          rightOfRange = this.props.collectionMetaData.currentDocCount;
        }
      } else {
        if (this.props.paginationDetails.currentPage === 1) {
          leftOfRange = 1;
        } else {
          leftOfRange = (this.props.paginationDetails.currentPage - 1) * this.state.rowsPerPage + 1;
        }

        if (this.props.paginationDetails.currentPage === this.props.paginationDetails.totalPages) {
          rightOfRange = this.props.collectionMetaData.totalDocCount;
        } else {
          rightOfRange = this.props.paginationDetails.currentPage * this.state.rowsPerPage;
          rightOfRange =
            rightOfRange < this.props.collectionMetaData.totalDocCount
              ? rightOfRange
              : this.props.collectionMetaData.totalDocCount;
        }
      }

      currentDocRange = leftOfRange === rightOfRange ? `${leftOfRange}` : `${leftOfRange}-${rightOfRange}`;
      return <p>{`${currentDocRange} of ${this.props.collectionMetaData.totalDocCount}`}</p>;
    } else {
      return <p>.. .. of ...</p>;
    }
  };

  render() {
    return (
      <div className="pagination">
        <div className="row-count-select">
          <span>Rows per page: </span>
          <span>
            <select value={this.state.rowsPerPage} onChange={this.handleRowsPerPageSelect}>
              {/* <option value="5">5</option> REMOVE AFTER TESTING */}
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
            </select>
          </span>
        </div>
        <div className="page-controls">
          <span
            id="decrease"
            className={`control-icon ${this.props.paginationDetails.currentPage === 1 ? "control-icon-inactive" : ""}`}
            onClick={this.pageControlHandler}
          >
            <FontAwesomeIcon icon="angle-left" />
          </span>
          <div className="page-count">{this.getPageCount()}</div>
          <span
            id="increase"
            className={`control-icon ${
              this.props.paginationDetails.currentPage === this.props.paginationDetails.totalPages ||
              !this.props.paginationDetails.totalPages
                ? "control-icon-inactive"
                : ""
            }`}
            onClick={this.pageControlHandler}
          >
            <FontAwesomeIcon icon="angle-right" />
          </span>
        </div>
      </div>
    );
  }
}

export default Pagination;
