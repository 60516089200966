import React from "react";

import "./footer-view.style.scss";

import Pagination from "../pagination/pagination.component";

const FooterView = ({
  appOPState,
  recordsCollectionMetaData,
  recordsPagination,
  keysCollectionMetaData,
  keysPagination,
  changeRowsPerPageState,
  changeCurrentPageState,
  clipboardState
}) => (
  <React.Fragment>
    {appOPState !== "sentinel-overview" ? (
      <div className="footer-view">
        <div className="footer-alerts">
          {clipboardState.copy ? (
            <p className="clipboard-alert">
              <span>Copied: </span>
              {clipboardState.copy}
            </p>
          ) : null}
        </div>

        {appOPState === "records" ? (
          <Pagination
            paginationDetails={recordsPagination}
            changeRowsPerPageState={changeRowsPerPageState}
            changeCurrentPageState={changeCurrentPageState}
            collectionMetaData={recordsCollectionMetaData}
          />
        ) : null}
        {appOPState === "keys" ? (
          <Pagination
            paginationDetails={keysPagination}
            changeRowsPerPageState={changeRowsPerPageState}
            changeCurrentPageState={changeCurrentPageState}
            collectionMetaData={keysCollectionMetaData}
          />
        ) : null}
      </div>
    ) : null}
  </React.Fragment>
);

export default FooterView;
