import React from "react";

import "./pop-up.style.scss";

import { KEY_INFO_NAV } from "../../dashboard-config/dashboard-config";
import KeyInfo from "./key-info.component";
import RecordInfo from "./record-info.component";

const PopUpView = ({
  appOPState,
  popUpView,
  recordsCollection,
  keysCollection,
  storedReadlogs,
  setStoredReadlogs,
  togglePopUpView,
  updateKeysCollection,
  loadingView,
  recordsSorting,
  keysSorting,
  triggerReload,
  resetAllStoredReadlogs
}) => (
  <React.Fragment>
    {appOPState === "records" && popUpView.records && recordsCollection ? (
      <div className="pop-up-wrapper">
        <div id="id-pop-up" className="pop-up-container">
          <RecordInfo
            docID={popUpView.records}
            currentDataCollection={recordsCollection}
            togglePopUpView={togglePopUpView}
            loadingView={loadingView}
            triggerReload={recordsSorting.column === "createdAt" ? triggerReload : null}
            storedReadlogs={storedReadlogs}
            setStoredReadlogs={setStoredReadlogs}
            resetAllStoredReadlogs={resetAllStoredReadlogs}
          />
        </div>
      </div>
    ) : null}
    {appOPState === "keys" && popUpView.keys && keysCollection ? (
      <div className="pop-up-wrapper">
        <div id="id-pop-up" className="pop-up-container">
          <KeyInfo
            popUpNav={KEY_INFO_NAV}
            docID={popUpView.keys}
            currentDataCollection={keysCollection}
            togglePopUpView={togglePopUpView}
            updateKeysCollection={updateKeysCollection}
            loadingView={loadingView}
            triggerReload={triggerReload}
            storedReadlogs={storedReadlogs}
            setStoredReadlogs={setStoredReadlogs}
            resetAllStoredReadlogs={resetAllStoredReadlogs}
          />
        </div>
      </div>
    ) : null}
  </React.Fragment>
);

export default PopUpView;
