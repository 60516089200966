import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReadLog from "./readlog/readlog.component";
import DashboardLoading from "../loading/dashboard-loading.component";

class RecordInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordData: null,
      loadingReadlog: false
    };
  }

  //#region ---------------------------------- PRE-PROCESSORS ----------------------------------
  setCurrentRecordData = (callbackFunction = null) => {
    let currDocID = this.props.docID.split(":")[0];
    let currDoc = null;

    this.props.currentDataCollection.forEach(element => {
      if (element.docID === currDocID) {
        currDoc = element;
      }
    });

    if (currDoc) {
      this.setState({ docID: currDocID, recordData: currDoc }, () => {
        // console.log(this.state.recordData);
        if (callbackFunction) {
          callbackFunction();
        }
      });
    } else {
      this.setState({ docID: currDocID, recordData: null, loadingReadlog: false }, () => {
        // console.log(this.state.recordData);
        this.props.togglePopUpView();
      });
    }
  };

  //#endregion ------------------------------- PRE-PROCESSORS ----------------------------------

  setLoadingReadlogState = newState => {
    this.setState({ loadingReadlog: newState });

    // console.trace(newState);
  };

  refreshHandler = event => {
    if (!event.currentTarget.classList.contains("refresh-inactive")) {
      if (this.props.triggerReload) {
        this.props.triggerReload(false);

        this.props.resetAllStoredReadlogs("Readlog Reset Point on Record Info Refresh Handler");
      }
    }
  };

  componentDidMount() {
    this.setCurrentRecordData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentDataCollection !== this.props.currentDataCollection) {
      this.setCurrentRecordData(() => {
        this.setLoadingReadlogState(true);
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* ------------ ----------------- ---------- POP UP HEADER ------------ ----------------- ---------- */}
        {this.state.loadingReadlog ? <DashboardLoading loadingMessage={null} /> : null}
        <div className="pop-up-header">
          <div className="pu-header-left">
            {/* Close Button */}
            <div
              className="pop-up-close"
              onClick={() => {
                this.props.togglePopUpView();
              }}
            >
              <FontAwesomeIcon icon="arrow-left" size="1x" />
            </div>

            {/* Heading */}
            <p className="pop-up-heading">{`Hardware Token : ${this.props.docID.split(":")[1]}`}</p>
          </div>

          <div className="pu-header-right">
            {this.props.triggerReload ? (
              <div
                title="Reload"
                className={`refresh ${this.props.loadingView.is ? "refresh-inactive" : ""}`}
                onClick={this.refreshHandler}
              >
                <FontAwesomeIcon icon="sync" />
              </div>
            ) : null}
            <div className="pop-up-nav">
              <div className="nav-item pu-active-nav">Read Log</div>
            </div>
          </div>
        </div>

        {/* ------------ ----------------- ---------- POP UP BODY ------------ ----------------- ---------- */}
        <div className="pop-up-body">
          {this.state.docID && this.state.recordData ? (
            <ReadLog
              docID={this.state.docID}
              footerLabel={{ property: "License Key:", value: this.state.recordData.licenseKey }}
              loadingReadlog={this.state.loadingReadlog}
              setLoadingReadlogState={this.setLoadingReadlogState}
              storedReadlogs={this.props.storedReadlogs}
              setStoredReadlogs={this.props.setStoredReadlogs}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default RecordInfo;
