import React from "react";

import "./login.style.scss";
import { auth, signInWithGoogle } from "../firebase/firebase.utils";
import swal from "@sweetalert/with-react";

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: ""
    };
  }

  componentDidMount() {
    this.props.setNewSignUp(null);
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  logInHandler = event => {
    const currentEmail = this.state.email;
    const currentPassword = this.state.password;

    // eslint-disable-next-line no-useless-escape
    const emailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    const isEmailValid = emailPattern.test(currentEmail);

    if (isEmailValid && currentPassword.length > 5) {
      event.preventDefault();
      this.props.setLoadingState(true);
      auth.signInWithEmailAndPassword(currentEmail, currentPassword).catch(error => {
        if (error.code === "auth/user-not-found") {
          swal("Unauthorized account! You cannot access Sentinel LiME...", { button: false, icon: "error" });
        } else {
          swal(`${error.code}`, `${error.message}`, { button: false, icon: "error" });
        }

        this.props.setLoadingState(false);
        this.setState({ email: "", password: "" });
        console.log(error);
      });
    }
  };

  googleSignInHandler = () => {
    signInWithGoogle().catch(error => {
      swal("Google Sign In Failed!", `${error.message}`, { button: false, icon: "error" });
      console.log(error);
    });
  };

  render() {
    return (
      <div className="login-page">
        <div className="login">
          <h3>Sentinel LiME</h3>
          <h5>License Management Engine</h5>
          {/* ************* */}
          {/* Manual Log In */}
          {/* ************* */}
          <form>
            <input
              name="email"
              type="email"
              value={this.state.email}
              placeholder="Email"
              pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
              title="Enter a valid email!"
              required
              onChange={this.handleInputChange}
            ></input>
            <input
              name="password"
              type="password"
              value={this.state.password}
              placeholder="Password"
              pattern="^.{6,}$"
              title="at least 6 characters"
              required
              onChange={this.handleInputChange}
            ></input>
            <button
              type="submit"
              value="Login"
              onClick={event => {
                this.logInHandler(event);
              }}
            >
              Login
            </button>
          </form>
          {/* ******************** */}
          {/* Other Log In Options */}
          {/* ******************** */}
          <h6>Sign In options</h6>
          <button type="submit" value="Google Sign-In" className="google-button" onClick={this.googleSignInHandler}>
            Google Sign-In
          </button>
        </div>
      </div>
    );
  }
}

export default Login;
