import React from 'react';

import SentinelLogo from '../../images/sentinel-logo.png';
import './welcome-back.style.scss';

const WelcomeBack = () => (
    <div className="welcome-back">
        <img src={SentinelLogo} alt="sentinel-logo" />
    </div>
);

export default WelcomeBack;