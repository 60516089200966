import React from "react";

import Dashboard from "./modules/dashboard";
import Login from "./modules/login";
import Signup from "./modules/signup";
import { auth, firestore } from "./firebase/firebase.utils";

import Loading from "./components/loading/loading.component";
import WelcomeBack from "./components/loading/welcome-back.component";

import swal from "@sweetalert/with-react";

//#region  --------Creating FontAwesome Library for this Project----------
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimes,
  faTimesCircle,
  faCaretDown,
  faCaretUp,
  faChevronRight,
  faChevronLeft,
  faAngleRight,
  faAngleLeft,
  faCog,
  faArrowLeft,
  faExchangeAlt,
  faPlus,
  faSearch,
  faExclamationTriangle,
  faFeatherAlt,
  faDraftingCompass,
  faDesktop,
  faRandom,
  faUndo,
  faInfoCircle,
  faSync
} from "@fortawesome/free-solid-svg-icons";
// import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
  faTimes,
  faTimesCircle,
  faCaretDown,
  faCaretUp,
  faChevronRight,
  faChevronLeft,
  faAngleRight,
  faAngleLeft,
  faCog,
  faArrowLeft,
  faExchangeAlt,
  faPlus,
  faSearch,
  faExclamationTriangle,
  faFeatherAlt,
  faDraftingCompass,
  faDesktop,
  faRandom,
  faUndo,
  faInfoCircle,
  faSync
);
//#endregion --------Creating FontAwesome Library for this Project----------

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      newSignUp: null,
      keepSignedIn: localStorage.getItem("s_lime_uid") ? true : null,
      currentUser: null
    };
  }

  setNewSignUp = signupDetails => {
    this.setState({ newSignUp: signupDetails });
  };

  setLoadingState = value => {
    this.setState({ loading: value });
  };

  unsubscribeFromAuth = null;
  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        // console.log(userAuth.getIdToken()); // REMOVE
        this.setLoadingState(true);
        const userRef = firestore.doc(`sentinels/${userAuth.uid}`);
        userRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const userDetails = {
                photoURL: userAuth.photoURL,
                ...doc.data()
              };

              this.setState({ currentUser: { ...userDetails } }, () => {
                // console.log(this.state.currentUser);
                localStorage.setItem("s_lime_uid", userAuth.uid); // value does not matter
                this.setLoadingState(false);
              });
            } else if (this.state.newSignUp) {
              let newCurrentUser;
              switch (this.state.newSignUp) {
                case "google-signup":
                  newCurrentUser = { role: "googleUser", ...userAuth };
                  break;
                default:
                  newCurrentUser = { ...this.state.newSignUp };
                  break;
              }
              //#region  --Save Newly Authenticated User to Sentinel User Collection
              firestore
                .collection("sentinels")
                .doc(userAuth.uid)
                .set({
                  displayName: newCurrentUser.displayName,
                  email: newCurrentUser.email,
                  role: newCurrentUser.role
                })
                .then(() => {
                  this.setState({ currentUser: newCurrentUser }, () => {
                    localStorage.setItem("s_lime_uid", userAuth.uid);
                    this.setLoadingState(false);
                  });
                })
                .catch(error => {
                  swal("User Creation Failed!", `${error.message}`, { button: false, icon: "error" });
                  this.setLoadingState(false);
                  auth.signOut();
                  console.log(error);
                });
              //#endregion  --Save Newly Authenticated User to Sentinel User Collection
            } else {
              swal("Unauthorized account! You cannot access Sentinel LiME...", { button: false, icon: "error" });
              this.setLoadingState(false);
              auth.signOut();
            }
          })
          .catch(error => {
            swal("Cloud Connection Failed!", `${error.message}`, { button: false, icon: "error" });
            this.setLoadingState(false);
            console.log(error);
          });
      } else {
        this.setState({ currentUser: null, keepSignedIn: null }, () => {
          localStorage.removeItem("s_lime_uid");
          // console.log(this.state.currentUser);
        });
      }
    });
  }

  //  Do this to prevent any memory leakes when the component is unmounted from DOM
  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  //  Conditionally render appView
  renderAppView = () => {
    if (this.state.currentUser || this.state.keepSignedIn) {
      if (this.state.currentUser) {
        if (this.state.currentUser.role === "superAdmin") {
          return (
            <Signup
              currentUser={this.state.currentUser}
              setNewSignUp={this.setNewSignUp}
              setLoadingState={this.setLoadingState}
            />
          );
        } else {
          return <Dashboard currentUser={this.state.currentUser} setLoadingState={this.setLoadingState} />;
        }
      } else {
        return <WelcomeBack />;
      }
    } else {
      return <Login setNewSignUp={this.setNewSignUp} setLoadingState={this.setLoadingState} />;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? <Loading keepSignedIn={this.state.keepSignedIn} /> : null}
        {this.renderAppView()}
      </React.Fragment>
    );
  }
}

export default App;
