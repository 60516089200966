import React from "react";

import "./user-info.style.scss";
import { auth } from "../../firebase/firebase.utils";

const UserInfo = ({ currentUser, addToClass }) => (
  <div className={`user-info ${addToClass ? addToClass : ""}`}>
    <div className="user-wrapper">
      <div
        className="user-avatar"
        style={{
          backgroundImage: currentUser.photoURL ? `url(${currentUser.photoURL})` : null
        }}
      >
        {currentUser.photoURL ? null : getAvatar(currentUser.displayName)}
      </div>
      {/* <div className="user-details"> */}
      <div className="user-name">{currentUser.displayName}</div>
      <div className="user-email">{currentUser.email}</div>
      <div
        className="logout"
        onClick={() => {
          auth.signOut();
        }}
      >
        Logout
      </div>
      {/* </div> */}
    </div>
  </div>
);

function getAvatar(displayName) {
  let avatarString;

  if (displayName) {
    if (displayName.includes(" ")) {
      var tempString = displayName.split(" ");
      avatarString = tempString[0].slice(0, 1).toUpperCase() + tempString[1].slice(0, 1).toUpperCase();
    } else {
      avatarString = displayName.slice(0, 1).toUpperCase() + displayName.slice(displayName.length - 1).toUpperCase();
    }
  }

  return avatarString;
}

export default UserInfo;
