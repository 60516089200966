import React from "react";

import "./data-table.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headings: this.props.tableHeadings
    };
  }

  // ------- ---------- NOT IN USE ------- -------------
  // handleDoubleClickSort = event => {
  //   if (event.currentTarget.classList.value === "sorting-col") {
  //     this.props.changeSortingState(event.currentTarget.id, true);
  //     // console.log(event.currentTarget.id);
  //   }
  // };

  handleSingleClickSort = event => {
    let targetColumn = event.currentTarget.id;

    if (event.currentTarget.classList.contains("sorting-col")) {
      if (this.props.sortingConfig.column !== targetColumn) {
        // console.log(targetColumn);
        this.props.changeSortingState(targetColumn);
      } else {
        this.props.changeSortingState(event.currentTarget.id, true);
        // console.log(event.currentTarget.id);
      }
    }
  };

  popUpHandler = event => {
    // console.log(event.currentTarget.id);
    this.props.togglePopUpView(event.currentTarget.id);
  };

  clipboardTimeout;
  copyToClipboard = event => {
    let copiedValue = event.currentTarget.getAttribute("data-clip");

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copiedValue)
        .then(() => {
          // console.log(copiedValue);
          this.props.changeClipboardState(copiedValue);

          if (this.clipboardTimeout) {
            clearTimeout(this.clipboardTimeout);
          }

          this.clipboardTimeout = setTimeout(() => {
            this.props.changeClipboardState();
          }, 2000);
        })
        .catch(() => {
          console.log("Failed to copy text.");
        });
    } else {
      console.log(navigator);
      this.props.changeClipboardState("Failed due to insufficient permissions!!");

      if (this.clipboardTimeout) {
        clearTimeout(this.clipboardTimeout);
      }

      this.clipboardTimeout = setTimeout(() => {
        this.props.changeClipboardState();
      }, 2000);
    }
  };

  setSortingUI = h => {
    if (h.sorting) {
      return (
        <div className="icon">
          <FontAwesomeIcon
            className={this.props.sortingConfig.column === h.key ? "active-col" : ""}
            icon={`caret-${this.props.sortingConfig.order === "desc" ? "down" : "up"}`}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  tableHeadings = () => {
    return (
      <React.Fragment>
        {this.state.headings.map((h, index) => (
          <th
            key={h.key}
            id={h.key}
            className={`${h.sorting ? "sorting-col" : ""}`}
            // onDoubleClick={this.handleDoubleClickSort}
            onClick={this.handleSingleClickSort}
          >
            <p>{h.label}</p>
            {this.setSortingUI(h)}
          </th>
        ))}
      </React.Fragment>
    );
  };

  setRecordTableBody = () => {
    return (
      <tbody>
        {this.props.dataCollection.map((row, index) => (
          <tr key={row.docID} id={row.docID} className={this.isEvenRow(index + 1) ? "even-tr" : null}>
            <td
              id={`${row.docID}:${row.hardwareToken}`}
              className="operation-td"
              onMouseEnter={this.highlightTableRow}
              onMouseLeave={this.removeHighlightedTableRow}
              onClick={this.popUpHandler}
              // title="Settings"
            >
              <FontAwesomeIcon icon="feather-alt" className="operation-icon" color="#fff" />
            </td>
            <td key={row.createdAt.seconds}>{this.getDate(row.createdAt)}</td>
            <td key={row.lastReadAt.nanoseconds}>{this.getDate(row.lastReadAt)}</td>
            <td
              className="interactive-td"
              data-clip={row.hardwareToken}
              onDoubleClick={this.copyToClipboard}
              key={row.hardwareToken}
            >
              <div className="td-flex">
                {row.hardwareToken}
                {row.isTransferred ? <p className="td-flags transferred">transferred</p> : null}
              </div>
            </td>
            <td
              className="interactive-td"
              data-clip={row.licenseKey}
              onDoubleClick={this.copyToClipboard}
              key={row.licenseKey}
            >
              <div className="td-flex">
                {row.licenseKey}
                <div className="td-flex-group">
                  {row.isRefunded ? <p className="td-flags refunded">refunded</p> : null}
                  {row.isBlocked ? <p className="td-flags blocked">blocked</p> : null}
                </div>
              </div>
            </td>
            {/* <td className={`td-status td-status-${row.status.toLowerCase()}`} key={row.status}>
              {row.status}
            </td> */}
          </tr>
        ))}
      </tbody>
    );
  };

  setKeysTableBody = () => {
    return (
      <tbody>
        {this.props.dataCollection.map((row, index) => (
          <tr key={row.docID} className={this.isEvenRow(index + 1) ? "even-tr" : null}>
            <td
              id={row.docID}
              className="operation-td"
              onMouseEnter={this.highlightTableRow}
              onMouseLeave={this.removeHighlightedTableRow}
              onClick={this.popUpHandler}
              // title="Settings"
            >
              <FontAwesomeIcon icon="cog" className="operation-icon" color="#fff" />
            </td>
            <td>{this.getDate(row.createdAt)}</td>
            <td>{this.getDate(row.modifiedAt)}</td>
            <td className="interactive-td" data-clip={row.docID} onDoubleClick={this.copyToClipboard}>
              {row.docID}
            </td>
            <td>
              {/* {row.isActivated ? <p className="td-flags activated">activated</p> : null} */}

              {row.isModified ? <p className="td-flags modified">modified</p> : null}

              {row.isBlocked ? <p className="td-flags blocked">blocked</p> : null}

              {row.isRefunded ? <p className="td-flags refunded">refunded</p> : null}

              {row.isTransferred ? <p className="td-flags transferred">transferred</p> : null}

              {row.isLeaked ? <p className="td-flags leaked">leaked</p> : null}
            </td>
            <td className={`td-status td-status-${row.status.toLowerCase()}`}>
              <span>{row.status}</span>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  highlightTableRow = event => {
    document.getElementById(`${event.currentTarget.id}`).parentElement.classList.add("highlight-tr");
  };

  removeHighlightedTableRow = event => {
    document.getElementById(`${event.currentTarget.id}`).parentElement.classList.remove("highlight-tr");
  };

  isEvenRow = value => {
    if (value % 2 === 0) {
      return true;
    } else {
      return false;
    }
  };

  getDate = dateO => {
    let date = new Date(dateO.seconds * 1000);
    // console.log(date);
    return date.toLocaleString();
  };

  render() {
    return (
      <div className="table-wrapper">
        <table className="data-table">
          <thead>
            <tr className="table-headings">{this.tableHeadings()}</tr>
          </thead>
          {this.props.collectionType === "records" ? this.setRecordTableBody() : null}
          {this.props.collectionType === "keys" ? this.setKeysTableBody() : null}
        </table>
      </div>
    );
  }
}

export default DataTable;
