import React from "react";

// import "./device-history.style.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DeviceHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyData: this.props.keyData
    };
  }

  getActivatedInfo = () => {
    return (
      <React.Fragment>
        {/* START IB-ROWS */}
        {this.state.keyData.activatedList.map((pc, index) => (
          <div key={`ib-gwi-activated-${index}`} className="ib-group-with-icon">
            <div className={`ib-group-icon ${this.isActivePC(pc) ? "ib-gi-active" : ""}`}>
              <FontAwesomeIcon icon="desktop" />
            </div>
            <div className="ib-group">
              <div className="ib-row">
                <h5 className="ib-property">Hardware ID :</h5>
                <div className="ib-value ib-value-mono">{pc.hwt}</div>
              </div>
              <div className="ib-row">
                <h5 className="ib-property">Activated At :</h5>
                {/* <div className="ib-value">{this.getDate(pc.at)}</div> */}

                {pc.at.length > 1 ? (
                  <div id={`activated:${pc.hwt}`} className="ib-value mini-pop-up" onClick={this.initMiniPopUp}>
                    <p>show</p>
                  </div>
                ) : (
                  <div className="ib-value">{this.getDate(pc.at[0])}</div>
                )}
              </div>
              <div className="ib-row add-margin-top">
                {/* <h5 className="ib-property">Read Log :</h5> */}
                <div className="ib-value">
                  <span
                    className="action-btn general-btn load-readlog-btn"
                    data-record-info={`${pc.recordId}:${pc.hwt}`}
                    onClick={this.initReadlogView}
                    title="Show Readlog"
                  >
                    <FontAwesomeIcon icon="feather-alt" />
                    Readlog
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* END IB-ROWS */}
      </React.Fragment>
    );
  };

  initReadlogView = event => {
    let recordInfo = event.currentTarget.getAttribute("data-record-info").split(":");

    let hwObject = {
      recordID: recordInfo[0],
      hwt: recordInfo[1]
    };

    // console.log(hwObject);
    this.props.setShowReadlogForState(hwObject);
  };

  getTransferredInfo = () => {
    return (
      <React.Fragment>
        {this.state.keyData.transferList.map((transfer, index) => (
          <div key={`ib-gwi-transfers-${index}`} className="ib-group-with-icon">
            <div className="ib-group-icon ib-gi-transfer">
              <FontAwesomeIcon icon="random" />
            </div>

            <div key={index} className="ib-group">
              <div className="ib-row">
                <h5 className="ib-property">Transferred At :</h5>
                <div className="ib-value">{this.getDate(transfer.at)}</div>
              </div>
              <div className="ib-row">
                <h5 className="ib-property">Issued By :</h5>
                <div className="ib-value">{`${transfer.by.name} (${transfer.by.email})`}</div>
              </div>
              <div className="ib-row">
                <h5 className="ib-property">Amount :</h5>
                <div className="ib-value">{transfer.count}</div>
              </div>
              <div className="ib-row">
                <h5 className="ib-property">Transferred To :</h5>
                <div className="ib-value ib-value-mono">
                  {transfer.newList.map((pc, idx) => (
                    <span key={idx} className={`tf-pc ${this.getNewTFClass(index, pc)}`}>
                      {pc}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  };

  getNewTFClass = (index, currentPC) => {
    let oldPCList = [];

    for (let i = 0; i <= index; i++) {
      this.state.keyData.transferList[i].oldList.forEach(pc => {
        if (!oldPCList.includes(pc)) {
          oldPCList.push(pc);
        }
      });
    }

    // console.log(index);
    // console.log(oldPCList);

    if (oldPCList.includes(currentPC)) {
      return "";
    } else {
      return "new-tf";
    }
  };

  // ------- Mini Pop Up ---------
  initMiniPopUp = event => {
    if (!event.currentTarget.classList.contains("active-mpu")) {
      event.currentTarget.classList.add("active-mpu");
      this.setState({ activeMiniPopUp: event.currentTarget.id });

      let tempIDArray = event.currentTarget.id.split(":");

      let hardwareID = tempIDArray[1];
      let hardwareObj;
      let mpuContent;

      if (tempIDArray[0] === "activated") {
        this.state.keyData.activatedList.forEach(hwObj => {
          if (hwObj.hwt === hardwareID) {
            hardwareObj = hwObj;
          }
        });

        mpuContent = hardwareObj.at.slice(0);
      }

      this.createMiniPopUp(event.currentTarget.id, mpuContent);
      // console.log(hardwareObj);
    }
  };

  createMiniPopUp = (targetID, mpuContent) => {
    let optimalRowCount = 50; // LIMIT ROWS HERE...

    const logList = document.createElement("ul");
    logList.classList.add("mpu-content");

    let logs_left = null;
    if (mpuContent.length > optimalRowCount) {
      mpuContent = mpuContent.slice(0, optimalRowCount);

      logs_left = document.createElement("li");
      logs_left.innerText = `${optimalRowCount} row limit reached...`;
    }

    mpuContent.forEach(log => {
      let log_li = document.createElement("li");

      log_li.innerText = this.getDate(log);

      logList.appendChild(log_li);
    });

    if (logs_left) {
      logList.appendChild(logs_left);
    }

    const mini_pop_up = document.getElementById(`${targetID}`);
    mini_pop_up.appendChild(logList);

    //   console.log(mini_pop_up);
  };

  handleClickOutside = event => {
    if (this.state.activeMiniPopUp) {
      let popUpElement = document.getElementById(this.state.activeMiniPopUp);

      if (!popUpElement.contains(event.target)) {
        popUpElement.classList.remove("active-mpu");
        popUpElement.removeChild(popUpElement.lastChild);

        this.setState({ activeMiniPopUp: null });
      }
    }
  };
  // ------- Mini Pop Up ---------

  // ------- Helper Functions ---------
  isActivePC = activatedPC => {
    let tempActiveList = this.state.keyData.activeList;

    if (tempActiveList && tempActiveList.length > 0) {
      return tempActiveList.some(activePC => {
        return activePC.recordId === activatedPC.recordId;
      });
    }

    return false;
  };

  getDate = dateO => {
    let date = new Date(dateO.seconds * 1000);
    // console.log(date);
    return date.toLocaleString();
  };
  // ------- Helper Functions ---------

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keyData !== this.props.keyData) {
      this.setState({ keyData: this.props.keyData }, () => {
        // console.log(this.state.keyData);
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <React.Fragment>
        {/* ACTIVATED INFO */}
        <div className="info-block">
          {this.state.keyData.activatedList.length > 0 ? (
            <React.Fragment>
              <h4 className="ib-heading">{`Activated PC(s)`}</h4>
              <div className="ib-body">{this.getActivatedInfo()}</div>
            </React.Fragment>
          ) : (
            <div className="ib-body">
              <div className="ib-group colorless">
                <p className="ib-faded-label">
                  <FontAwesomeIcon icon="desktop" />
                </p>
                <p className="ib-faded-label">{`No Activated PC(s)`}</p>
              </div>
            </div>
          )}
        </div>
        {/* ACTIVATED INFO */}

        {/* TRANSFER INFO */}
        <div className="info-block">
          {this.state.keyData.transferList.length > 0 ? (
            <React.Fragment>
              <h4 className="ib-heading">Transfer Details</h4>
              <div className="ib-body">{this.getTransferredInfo()}</div>
            </React.Fragment>
          ) : (
            <div className="ib-body">
              <div className="ib-group colorless">
                <p className="ib-faded-label">
                  <FontAwesomeIcon icon="random" />
                </p>
                <p className="ib-faded-label">No Transfers</p>
              </div>
            </div>
          )}
        </div>
        {/* TRANSFER INFO */}
      </React.Fragment>
    );
  }
}

export default DeviceHistory;
