import React from "react";

import "./loading.style.scss";
import { ReactComponent as SpinnerLarge } from "../../images/Spinner-Large.svg";
import { ReactComponent as Spinner } from "../../images/Spinner.svg";

const Loading = ({ keepSignedIn }) => (
  <div className="loading-wrapper">
    <div className="loading">{keepSignedIn ? <SpinnerLarge /> : <Spinner />}</div>
  </div>
);

export default Loading;
