import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCt4A7QRhr4yIL1Bb8cJyC6OD7lcYAFP8o",
  authDomain: "sentinel-lime.firebaseapp.com",
  databaseURL: "https://sentinel-lime.firebaseio.com",
  projectId: "sentinel-lime",
  storageBucket: "sentinel-lime.appspot.com",
  messagingSenderId: "724117751387",
  appId: "1:724117751387:web:31b236a2a7e318ba15617e",
  measurementId: "G-S1RK5NK9GP"
};

firebase.initializeApp(config);

// To auth using google or other accounts
export const auth = firebase.auth();

// To access our Database
export const firestore = firebase.firestore();

// For Google Sign In Pop Up
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase; // if we ever need the whole library
