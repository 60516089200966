import React from "react";

import "./sidebar-nav.style.scss";

import {SIDEBAR_NAV_ITEMS} from '../../dashboard-config/dashboard-config'

const SidebarNav = ({appOPState, changeAppOperationalState}) => (
  <div className="sidebar-nav">
    {SIDEBAR_NAV_ITEMS.map(e => (
      <div
        key={e.navID}
        data-op-state={e.navOPState}
        className={`nav-item ${(appOPState === e.navOPState) ? 'active-nav': ""}`}
        onClick={changeAppOperationalState}
      >
        {e.itemLabel}
      </div>
    ))}
  </div>
);

export default SidebarNav;
