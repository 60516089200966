import React from "react";

import "./timeline.style.scss";

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyData: this.props.keyData,
      eventSorting: "oldest",
      eventFilter: "none"
    };
  }

  //---------- --------------- ----------------- UI HANDLERS --------------- -------------- ------------
  handleSortingChange = event => {
    this.setState({ eventSorting: event.target.value });
  };

  handleFilterChange = event => {
    this.setState({ eventFilter: event.target.value });
  };

  //---------- --------------- ------------- TIMELINE FUNCTIONS ------------ -------------- ------------
  getTimelineNodeList = () => {
    let tl_Nodes;

    if (this.state.eventSorting === "latest") {
      tl_Nodes = this.state.keyData.sortedEventArray.slice(0).reverse();
    } else {
      tl_Nodes = this.state.keyData.sortedEventArray;
    }

    return tl_Nodes;
  };

  renderTimeline = () => {
    let timelineNodeList = this.getTimelineNodeList();
    // console.log(timelineNodeList);

    return (
      <React.Fragment>
        {timelineNodeList.map((node, eIndex) => (
          <div key={`event${eIndex}`} className="tl-event-node">
            <div className="event-timestamp">
              <p>{this.getDate(node[1][0].at)}</p>
            </div>
            <ul className="event-list">{this.getEventList(node[1])}</ul>
          </div>
        ))}
      </React.Fragment>
    );
  };

  getEventList = eventList => {
    // if (this.state.eventSorting === "latest") {
    //   eventList = eventList.slice(0).reverse();
    // }

    return (
      <React.Fragment>
        {eventList.map((process, pIndex) => (
          <li key={`process${pIndex}`} className="event-item">
            {this.getEventActionDetails(process)}
          </li>
        ))}
      </React.Fragment>
    );
  };

  getEventActionDetails = process => {
    let action = process.action.toLowerCase();

    // console.log(process);
    // console.log(action);

    switch (action) {
      case "created":
        return this.getCreatedInfo(process);
      // break;
      case "activated":
        return this.getActivatedInfo(process);
      // break;
      case "blocked":
        return this.getBlockedInfo(process);
      // break;
      case "unblocked":
        return this.getUnblockedInfo(process);
      // break;
      case "refunded":
        return this.getRefundedInfo(process);
      // break;
      case "leaked":
        return this.getLeakedInfo(process);
      // break;
      case "transferred":
        return this.getTransferredInfo(process);
      // break;
      case "re-activated":
        return this.getActivatedInfo(process, true);
      // break;

      default:
        // return (
        //   <React.Fragment>
        //     <h5 className="ei-heading">{process.action} </h5>
        //     <div className="ei-description">Coming soon..</div>
        //   </React.Fragment>
        // );
        break;
    }
  };

  getCreatedInfo = process => {
    let autoInfo = null;
    let manualInfo = null;

    if (process.by.auto) {
      autoInfo = (
        <div className="ei-description">
          <span className="ei-em">{`${this.state.keyData.licenseKey} `}</span>
          <span> license key was added by </span>
          <span className="ei-em">{this.state.keyData.createdBy.hwt}</span>
          <span> device on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>
        </div>
      );
    } else {
      manualInfo = (
        <div className="ei-description">
          <span className="ei-em">{`${this.state.keyData.licenseKey} `}</span>
          <span> license key was manually added by </span>
          <span>
            <em>{`${process.by.name} `}</em>
          </span>
          <span>{`(${process.by.email})`}</span>
          <span> on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>
          {process.reason ? (
            <div className="ei-note">
              <em>Reason: </em> {process.reason}
            </div>
          ) : null}

          {process.remarks ? (
            <div className="ei-note">
              <em>Remarks: </em> {process.remarks}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        {autoInfo ? autoInfo : null}
        {manualInfo ? manualInfo : null}
      </React.Fragment>
    );
  };

  getActivatedInfo = (process, reActivate = false) => {
    if (reActivate) {
      return (
        <React.Fragment>
          <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
          <div className="ei-description">
            <span className="ei-em">{process.hwt}</span>
            <span> device was re-activated on </span>
            <span className="ei-date">{`${this.getDate(process.at)}`}</span>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
          <div className="ei-description">
            <span className="ei-em">{process.hwt}</span>
            <span> device was activated on </span>
            <span className="ei-date">{`${this.getDate(process.at)}`}</span>
          </div>
        </React.Fragment>
      );
    }
  };

  getBlockedInfo = process => {
    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        <div className="ei-description">
          <span> Blocked by </span>
          <span>
            <em>{`${process.by.name} `}</em>
          </span>
          <span>{`(${process.by.email})`}</span>
          <span> on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>

          {process.reason ? (
            <div className="ei-note">
              <em>Reason: </em> {process.reason}
            </div>
          ) : null}
          {process.remarks ? (
            <div className="ei-note">
              <em>Remarks: </em> {process.remarks}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  getUnblockedInfo = process => {
    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        <div className="ei-description">
          <span> Unblocked by </span>
          <span>
            <em>{`${process.by.name} `}</em>
          </span>
          <span>{`(${process.by.email})`}</span>
          <span> on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>

          {process.reason ? (
            <div className="ei-note">
              <em>Reason: </em> {process.reason}
            </div>
          ) : null}
          {process.remarks ? (
            <div className="ei-note">
              <em>Remarks: </em> {process.remarks}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  getRefundedInfo = process => {
    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        <div className="ei-description">
          <span> Refunded by </span>
          <span>
            <em>{`${process.by.name} `}</em>
          </span>
          <span>{`(${process.by.email})`}</span>
          <span> on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>

          {process.reason ? (
            <div className="ei-note">
              <em>Reason: </em> {process.reason}
            </div>
          ) : null}
          {process.remarks ? (
            <div className="ei-note">
              <em>Remarks: </em> {process.remarks}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  getLeakedInfo = process => {
    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        <div className="ei-description">
          <span> Leaked from </span>
          <span className="ei-em">{process.hwt}</span>
          <span> device on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>
        </div>
      </React.Fragment>
    );
  };

  getTransferredInfo = process => {
    return (
      <React.Fragment>
        <h5 className={`ei-heading ei-heading-${process.action.toLowerCase()}`}>{process.action} </h5>
        <div className="ei-description">
          <span>{`Transfer granted for ${process.count} device(s) by `}</span>
          <span>
            <em>{`${process.by.name} `}</em>
          </span>
          <span>{`(${process.by.email})`}</span>
          <span> on </span>
          <span className="ei-date">{`${this.getDate(process.at)}`}</span>

          {process.remarks ? (
            <div className="ei-note">
              <em>Remarks: </em> {process.remarks}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  getDate = dateO => {
    let date = new Date(dateO.seconds * 1000);
    // console.log(date);
    return date.toLocaleString();
  };

  //---------- --------------- --------------- REACT FUNCTIONS ------------- -------------- ------------
  componentDidUpdate(prevProps) {
    if (prevProps.keyData !== this.props.keyData) {
      this.setState({ keyData: this.props.keyData }, () => {
        // console.log(this.state.keyData);
      });
    }
  }

  render() {
    return (
      <div className="timeline">
        <div className="tl-action-bar">
          <div className="actions-wrapper">
            {/* TODO <div className="ab-filter">
              <span className="ab-label">Filter by:</span>
              <select
                className="ab-select"
                value={this.state.eventFilter}
                onChange={this.handleFilterChange}
              >
                <option value="none">None</option>
                <option value="blocked">Blocked</option>
                <option value="unblocked">Unblocked</option>
                <option value="refunded">Refunded</option>
                <option value="transferred">Transferred</option>
                <option value="leaked">Leaked</option>
              </select>
            </div> */}
            <div className="ab-sort">
              <select className="ab-select" value={this.state.eventSorting} onChange={this.handleSortingChange}>
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
        </div>
        <div id="id-tl-body" className="tl-body">
          {this.renderTimeline()}
        </div>
      </div>
    );
  }
}

export default Timeline;
