import React from "react";

import "./sentinel-overview.style.scss";

import { calculateTotalDatewiseDataSetValueFor } from "../../utility-functions/dashboard.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SentinelOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sentinelOverviewCollection: this.props.sentinelOverviewCollection,
      sentinelOverviewDateRangeFilter: this.props.sentinelOverviewDateRangeFilter
    };
  }

  //#region ------ UI TEMPLATES ------
  getOverviewCardTemplateFor = (sectionHeadings = undefined, datewiseDataSet = null, totalDataSet = null) => {
    return (
      <React.Fragment>
        {Object.keys(this.state.sentinelOverviewCollection).length === 0 ? null : (
          <div className="info-block font-color-faded">
            <div className="ib-heading font-size-20 ib-heading-align-center text-to-uppercase">
              <p>{sectionHeadings.title ? sectionHeadings.title : "NOT-SET"}</p>
              <p className="tooltip-wrapper">
                <span className="tooltip-trigger">
                  <FontAwesomeIcon icon="info-circle" />
                </span>
                <span className="tooltip-content">{sectionHeadings.subText ? sectionHeadings.subText : "NOT-SET"}</span>
              </p>
            </div>
            <div className="ib-body">
              {/* GROUP 01 */}
              {datewiseDataSet ? (
                <div className="ib-group colorless">
                  <div className="ib-row ib-row-align-center">
                    {datewiseDataSet.map((dataSet, dwsIndex) => (
                      <div key={`index-of-${dwsIndex}`} className="ib-column">
                        {dataSet.title ? <h6 className="ib-subheading">{dataSet.title}</h6> : null}

                        <div className="epic-character-group">
                          <p className="epic-character">
                            {dataSet.majorContent || dataSet.majorContent === 0
                              ? `${parseInt(dataSet.majorContent).toLocaleString()}`
                              : "NOT-SET"}
                          </p>

                          {dataSet.minorContent ? (
                            <div>
                              {dataSet.minorContent.map((mnrContent, mnrIndex) => (
                                <p
                                  key={`mnrIndex-${mnrIndex}`}
                                  className={`tiny-character ${mnrContent[1] ? mnrContent[1] : ""}`}
                                >
                                  {mnrContent[0] || mnrContent[0] === 0
                                    ? `${parseInt(mnrContent[0]).toLocaleString()}`
                                    : "NOT-SET"}
                                </p>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {/* GROUP 01 */}

              {/* GROUP 02 */}
              <div className="ib-group colorless">
                <div className="ib-row ib-row-align-center ib-row-faded">
                  {totalDataSet
                    ? totalDataSet.map((dataSet, tdIndex) => (
                        <div
                          key={`total-dataset-${tdIndex}`}
                          className={`ib-column ${dataSet.customClass ? dataSet.customClass : ""}`}
                        >
                          <div className="ib-column-flex-content">
                            <h5 className="ib-property text-to-uppercase">
                              {dataSet.title ? dataSet.title : "NOT-SET"}
                            </h5>
                            <div className="ib-value font-weight-600">
                              {dataSet.value || dataSet.value === 0
                                ? `${parseInt(dataSet.value).toLocaleString()}`
                                : "NOT-SET"}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              {/* GROUP 02 */}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  //#endregion --- UI TEMPLATES ------

  //#region ------ RENDER FUNCTIONS ------
  // --- Validated API Requests
  renderValidatedAPIRequestsView = () => {
    let sectionHeadings = {
      title: "Validated API Requests",
      subText: "Number of requests received and validated at /api/validate"
    };
    let datewiseDataSetRow = this.getDatewiseDataSetRow("validateRequests", "validatedRecords"); //must be corresponding database property names

    let totalDataSet = [];
    if (this.state.sentinelOverviewCollection.summary) {
      totalDataSet.push({
        title: "Total:",
        value: this.state.sentinelOverviewCollection.summary.validateRequests
      });
      totalDataSet.push({
        title: "Accepted:",
        value: this.state.sentinelOverviewCollection.summary.validatedRecords,
        customClass: "font-color-green"
      });
      totalDataSet.push({
        title: "Rejected:",
        value: `${this.state.sentinelOverviewCollection.summary.validateRequests -
          this.state.sentinelOverviewCollection.summary.validatedRecords}`,
        customClass: "font-color-red"
      });
    }

    return this.getOverviewCardTemplateFor(sectionHeadings, datewiseDataSetRow, totalDataSet);
  };

  // --- Activated PCs
  renderActivatedPCsView = () => {
    let sectionHeadings = {
      title: "Activated PCs",
      subText: "Number of new PC's that activated a License Key"
    };
    let datewiseDataSetRow = this.getDatewiseDataSetRow("activatedRecords"); //must be corresponding database property name

    let totalDataSet = [];
    if (this.state.sentinelOverviewCollection.summary) {
      totalDataSet.push({
        title: "Total:",
        value: this.state.sentinelOverviewCollection.summary.activatedRecords
      });
    }

    return this.getOverviewCardTemplateFor(sectionHeadings, datewiseDataSetRow, totalDataSet);
  };

  // --- Activated Keys
  renderActivatedKeysView = () => {
    let sectionHeadings = {
      title: "Activated Keys",
      subText: "Number of new License Keys that were activated"
    };
    let datewiseDataSetRow = this.getDatewiseDataSetRow("activatedKeys"); //must be corresponding database property name

    let totalDataSet = [];
    if (this.state.sentinelOverviewCollection.summary) {
      totalDataSet.push({
        title: "Total:",
        value: this.state.sentinelOverviewCollection.summary.activatedKeys
      });
    }

    return this.getOverviewCardTemplateFor(sectionHeadings, datewiseDataSetRow, totalDataSet);
  };

  // --- Leaked Key Count
  renderLeakedKeyCountView = () => {
    let sectionHeadings = {
      title: "Leaked Key Count",
      subText: "Number of License Keys with attempted piracy"
    };
    let datewiseDataSetRow = this.getDatewiseDataSetRow("leakedKeys"); //must be corresponding database property name

    let totalDataSet = [];
    if (this.state.sentinelOverviewCollection.summary) {
      totalDataSet.push({
        title: "Total:",
        value: this.state.sentinelOverviewCollection.summary.leakedKeys
      });
    }

    return this.getOverviewCardTemplateFor(sectionHeadings, datewiseDataSetRow, totalDataSet);
  };

  // --- Blocked Keys
  renderBlockedKeysView = () => {
    let sectionHeadings = {
      title: "Blocked Keys",
      subText: "Number of License Keys that were either refunded or blocked"
    };

    let datewiseDataSetRow = [];
    //#region ------CREATE DATEWISE DATASET ROW
    if (this.state.sentinelOverviewDateRangeFilter.active) {
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          { title: "Block Actions" },
          "blockedKeys",
          "refundedKeys",
          this.state.sentinelOverviewDateRangeFilter.dateFrom,
          this.state.sentinelOverviewDateRangeFilter.dateTo
        )
      );
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          { title: "Unblock Actions" },
          "unblockedKeys",
          null,
          this.state.sentinelOverviewDateRangeFilter.dateFrom,
          this.state.sentinelOverviewDateRangeFilter.dateTo
        )
      );
    } else if (this.state.sentinelOverviewCollection.summary) {
      datewiseDataSetRow.push({
        title: "Total Block Actions",
        majorContent: this.state.sentinelOverviewCollection.summary.blockedKeys,
        minorContent: [
          [this.state.sentinelOverviewCollection.summary.refundedKeys, "font-color-orange"],
          [
            this.state.sentinelOverviewCollection.summary.blockedKeys -
              this.state.sentinelOverviewCollection.summary.refundedKeys,
            "font-color-red"
          ]
        ]
      });
      datewiseDataSetRow.push({
        title: "Total Unblock Actions",
        majorContent: this.state.sentinelOverviewCollection.summary.unblockedKeys,
        minorContent: [
          [0, "font-color-orange"],
          [this.state.sentinelOverviewCollection.summary.unblockedKeys, "font-color-red"]
        ]
      });
    }
    //#endregion ---CREATE DATEWISE DATASET ROW

    let totalDataSet = [];
    //#region ------CREATE TOTAL DATASET
    if (this.state.sentinelOverviewCollection.summary) {
      let totalBlockedKeys =
        this.state.sentinelOverviewCollection.summary.blockedKeys -
        this.state.sentinelOverviewCollection.summary.unblockedKeys;

      totalDataSet.push({
        title: "Total:",
        value: totalBlockedKeys
      });
      totalDataSet.push({
        title: "Refunded:",
        value: this.state.sentinelOverviewCollection.summary.refundedKeys,
        customClass: "font-color-orange"
      });
      totalDataSet.push({
        title: "Manually Blocked:",
        value: `${totalBlockedKeys - this.state.sentinelOverviewCollection.summary.refundedKeys}`,
        customClass: "font-color-red"
      });
    }
    //#endregion ---CREATE TOTAL DATASET

    return this.getOverviewCardTemplateFor(sectionHeadings, datewiseDataSetRow, totalDataSet);
  };

  getDatewiseDataSetRow = (majorSectionType, minorSectionType = null) => {
    let datewiseDataSetRow = [];

    // --- WHEN DATE RANGE IS ACTIVE
    if (this.state.sentinelOverviewDateRangeFilter.active) {
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          null,
          majorSectionType,
          minorSectionType,
          this.state.sentinelOverviewDateRangeFilter.dateFrom,
          this.state.sentinelOverviewDateRangeFilter.dateTo
        )
      );
    } else {
      // --- WHEN DATE RANGE IS INACTIVE

      //#region    // --- PRESET: Today
      let datewiseDataSetColumnToday = {
        title: "Today"
      };
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          datewiseDataSetColumnToday,
          majorSectionType,
          minorSectionType,
          this.getDateRangeToday(),
          this.getDateRangeToday()
        )
      );
      //#endregion // --- PRESET: Today

      //#region    // --- PRESET: Last 7 Days
      let datewiseDataSetColumnL7D = {
        title: "Last 7 Days"
      };
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          datewiseDataSetColumnL7D,
          majorSectionType,
          minorSectionType,
          this.getDateRangeFromFor(7),
          this.getDateRangeToday()
        )
      );
      //#endregion // --- PRESET: Last 7 Days

      //#region    // --- PRESET: Last 30 Days
      let datewiseDataSetColumnL30D = {
        title: "Last 30 Days"
      };
      datewiseDataSetRow.push(
        this.getCalculatedDatewiseDataSetColumn(
          datewiseDataSetColumnL30D,
          majorSectionType,
          minorSectionType,
          this.getDateRangeFromFor(30),
          this.getDateRangeToday()
        )
      );
      //#endregion // --- PRESET: Last 30 Days
    }

    return datewiseDataSetRow;
  };

  getCalculatedDatewiseDataSetColumn = (
    tempDatewiseDataSetColumn = null,
    majorSectionType,
    minorSectionType,
    dateFrom,
    dateTo
  ) => {
    let datewiseDataSetColumn;

    if (tempDatewiseDataSetColumn) {
      datewiseDataSetColumn = tempDatewiseDataSetColumn;
    } else {
      datewiseDataSetColumn = {
        title: null
      };
    }

    //--- Set Major Content
    let calcMajorContent = calculateTotalDatewiseDataSetValueFor(
      majorSectionType,
      dateFrom,
      dateTo,
      this.state.sentinelOverviewCollection
    );
    datewiseDataSetColumn.majorContent = calcMajorContent;
    //--- Set Major Content

    //--- Set Minor Content
    if (majorSectionType === "unblockedKeys") {
      datewiseDataSetColumn.minorContent = [
        [0, "font-color-orange"],
        [calcMajorContent, "font-color-red"]
      ];
    }

    if (minorSectionType) {
      let calcMinorContent = calculateTotalDatewiseDataSetValueFor(
        minorSectionType,
        dateFrom,
        dateTo,
        this.state.sentinelOverviewCollection
      );

      let minorContentColor = "";
      if (minorSectionType === "validatedRecords") {
        minorContentColor = "font-color-green";
      }
      if (minorSectionType === "refundedKeys") {
        minorContentColor = "font-color-orange";
      }

      datewiseDataSetColumn.minorContent = [
        [calcMinorContent, minorContentColor],
        [calcMajorContent - calcMinorContent, "font-color-red"]
      ];
    }
    //--- Set Minor Content

    return datewiseDataSetColumn;
  };
  //#endregion --- RENDER FUNCTIONS ------

  //#region ------ HELPER FUNCTIONS ------
  getDateRangeFromFor = days => {
    let dateFrom = new Date();
    dateFrom.setTime(dateFrom.getTime() - days * 24 * 60 * 60 * 1000);
    dateFrom.setHours(0, 0, 0, 0);

    // console.log(dateFrom);
    return dateFrom;
  };

  getDateRangeToday = () => {
    let today = new Date();
    let newToday = new Date();
    // newToday.setTime(today.setHours(23, 59, 59, 999));
    newToday.setTime(today.setHours(0, 0, 0, 0));
    // console.log(newToday);

    return newToday;
  };
  //#endregion --- HELPER FUNCTIONS ------

  // ------ REACT FUNCTION -------
  componentDidUpdate(prevProps) {
    if (prevProps.sentinelOverviewCollection !== this.props.sentinelOverviewCollection) {
      this.setState({ sentinelOverviewCollection: this.props.sentinelOverviewCollection }, () => {
        // console.log("Collection Updated...");
      });
    }

    if (prevProps.sentinelOverviewDateRangeFilter !== this.props.sentinelOverviewDateRangeFilter) {
      this.setState({ sentinelOverviewDateRangeFilter: this.props.sentinelOverviewDateRangeFilter }, () => {
        // console.log("Date Filter Updated...");
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.sentinelOverviewCollection ? (
          <div className="sentinel-overview">
            <div className="so-block">{this.renderValidatedAPIRequestsView()}</div>
            <div className="so-block">{this.renderActivatedPCsView()}</div>
            <div className="so-block">{this.renderActivatedKeysView()}</div>
            <div className="so-block">{this.renderLeakedKeyCountView()}</div>
            <div className="so-block">{this.renderBlockedKeysView()}</div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default SentinelOverview;
