import React from "react";

import "./toolbar-view.style.scss";

import Toolbar from "../toolbar/toolbar.component";

const ToolbarView = ({
  appOPState,
  databaseStartingPoint,
  loadingView,
  recordsSearch,
  keysSearch,
  changeSearchState,
  recordsFilter,
  keysFilter,
  changeFilterState,
  sentinelOverviewDateRangeFilter,
  recordsDateRangeFilter,
  keysDateRangeFilter,
  changeDateRangeFilterState,
  triggerReload,
  resetAllStoredReadlogs
}) => (
  <div className="toolbar-view">
    {appOPState === "sentinel-overview" ? (
      <Toolbar
        collectionType={appOPState}
        databaseStartingPoint={databaseStartingPoint}
        loadingView={loadingView}
        defaultFilter={null}
        defaultDateRangeFilter={sentinelOverviewDateRangeFilter}
        changeDateRangeFilterState={changeDateRangeFilterState}
        searchState={null}
        triggerReload={triggerReload}
      />
    ) : null}
    {appOPState === "records" ? (
      <Toolbar
        collectionType={appOPState}
        databaseStartingPoint={databaseStartingPoint}
        loadingView={loadingView}
        defaultFilter={recordsFilter}
        changeFilterState={changeFilterState}
        defaultDateRangeFilter={recordsDateRangeFilter}
        changeDateRangeFilterState={changeDateRangeFilterState}
        searchState={recordsSearch}
        changeSearchState={changeSearchState}
        triggerReload={triggerReload}
        resetAllStoredReadlogs={resetAllStoredReadlogs}
      />
    ) : null}
    {appOPState === "keys" ? (
      <Toolbar
        collectionType={appOPState}
        databaseStartingPoint={databaseStartingPoint}
        loadingView={loadingView}
        defaultFilter={keysFilter}
        changeFilterState={changeFilterState}
        defaultDateRangeFilter={keysDateRangeFilter}
        changeDateRangeFilterState={changeDateRangeFilterState}
        searchState={keysSearch}
        changeSearchState={changeSearchState}
        triggerReload={triggerReload}
        resetAllStoredReadlogs={resetAllStoredReadlogs}
      />
    ) : null}
  </div>
);

export default ToolbarView;
