import React from "react";

import "./readlog.style.scss";

import { firestore } from "../../../firebase/firebase.utils";
import swal from "@sweetalert/with-react";

import Calendar from "../../calendar/calendar.component";

class ReadLog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      yearWiseReadLog: null,
      loadingReadlog: false
    };
  }

  loadReadLogFor = docID => {
    // console.log(`Loading Readlog: ${docID}`);

    let collectionQuery = firestore
      .collection("records")
      .doc(docID)
      .collection("readLog");

    let collectionData = {};
    collectionQuery
      .get()
      .then(collectionSnapshot => {
        collectionSnapshot.docs.forEach(doc => {
          if (!collectionData[doc.id]) {
            collectionData[doc.id] = {};
          }

          collectionData[doc.id] = {
            ...doc.data()
          };
        });

        if (this._isMounted) {
          let filteredCollectionData = this.getFilteredYearWiseReadLog(collectionData);

          this.setState({ yearWiseReadLog: filteredCollectionData }, () => {
            // console.log(this.state.yearWiseReadLog);
            this.props.setLoadingReadlogState(false);
          });

          this.props.setStoredReadlogs(docID, filteredCollectionData);
        }
      })
      .catch(error => {
        swal("Something Went Wrong!", `${error.message}`, { button: false, icon: "error" });
        this.props.setLoadingReadlogState(false);
        console.log(error);
      });
  };

  getFilteredYearWiseReadLog = collectionData => {
    // console.log(collectionData);
    let filteredYearWiseReadLog = {};
    let allMonthsArray = Object.values(collectionData);

    allMonthsArray.forEach(month => {
      let daysInThisMonth = Object.values(month);
      // console.log(daysInThisMonth);

      daysInThisMonth.forEach(day => {
        let dataArrayForThisDay = Object.values(day);
        // console.log(dataArrayForThisDay);

        dataArrayForThisDay.forEach(dataArray => {
          // console.log(dataArray);

          dataArray.forEach(timestamp => {
            let readlog = this.getDate(timestamp);
            let currYear = readlog.getFullYear();
            let currMonth = readlog.getMonth();
            let currDay = readlog.getDate();

            // console.log(readlog);
            // console.log(currYear, currMonth, currDay);

            //--- create filteredYearWiseReadLog
            if (!filteredYearWiseReadLog[currYear]) {
              filteredYearWiseReadLog[currYear] = {};
            }
            if (!filteredYearWiseReadLog[currYear][currMonth]) {
              filteredYearWiseReadLog[currYear][currMonth] = {};
            }
            if (!filteredYearWiseReadLog[currYear][currMonth][currDay]) {
              filteredYearWiseReadLog[currYear][currMonth][currDay] = [];
            }

            filteredYearWiseReadLog[currYear][currMonth][currDay].push(timestamp);
          });
        });
      });
    });

    // console.log(filteredYearWiseReadLog);

    return filteredYearWiseReadLog;
  };

  getDate = timestamp => {
    let date = new Date(timestamp.seconds * 1000);
    // console.log(date);

    return date;
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.props.storedReadlogs[this.props.docID]) {
      this.setState({ yearWiseReadLog: this.props.storedReadlogs[this.props.docID] }, () => {
        // console.log("Readlog found in stash...");
      });
    } else {
      this.props.setLoadingReadlogState(true);
    }
  }

  TRIGGER_LOADING = 0;
  componentDidUpdate(prevProps, newProps) {
    if (this.props.loadingReadlog) {
      this.TRIGGER_LOADING++;

      if (this.TRIGGER_LOADING === 1) {
        this.loadReadLogFor(this.props.docID);
      }
    } else {
      this.TRIGGER_LOADING = 0;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="readlog">
        {this.state.yearWiseReadLog ? (
          <Calendar
            yearWiseDateObject={this.state.yearWiseReadLog}
            footerLabel={this.props.footerLabel}
            calendarToolbarLabel={this.props.calendarToolbarLabel}
          />
        ) : null}
      </div>
    );
  }
}

export default ReadLog;
