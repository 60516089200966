import React from "react";
import { auth, signInWithGoogle } from "../firebase/firebase.utils";
import swal from "@sweetalert/with-react";

import "./dashboard.style.scss";
import "./signup.style.scss";

import UserInfo from "../components/user-info/user-info.component";

class Signup extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      password01: "",
      password02: "",
      error: null
    };
  }

  onChangeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  manualSignUpHandler = event => {
    const currentName = this.state.name;
    const currentEmail = this.state.email;
    const currentPassword01 = this.state.password01;
    const currentPassword02 = this.state.password02;

    // eslint-disable-next-line no-useless-escape
    const emailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    const isEmailValid = emailPattern.test(currentEmail);

    if (currentName.length > 2 && isEmailValid && currentPassword01.length > 5 && currentPassword02.length > 5) {
      event.preventDefault();
      const isPasswordMatching = currentPassword01 === currentPassword02;
      if (isPasswordMatching) {
        const signupDetails = {
          displayName: currentName,
          email: currentEmail,
          role: "sentinelUser"
        };
        this.props.setNewSignUp(signupDetails);
        this.props.setLoadingState(true);
        this.setState({
          error: null,
          name: "",
          email: "",
          password01: "",
          password02: ""
        });
        auth.createUserWithEmailAndPassword(currentEmail, currentPassword01).catch(error => {
          swal("User Creation Failed!", `${error.message}`, { button: false, icon: "error" });
          console.log(error);
        });
      } else {
        this.setState({ error: "Passwords do not match!" });
      }
    }
  };

  googleSignUpHandler = () => {
    this.props.setNewSignUp("google-signup");
    signInWithGoogle().catch(error => {
      swal("Google Sign Up Failed!", `${error.message}`, { button: false, icon: "error" });
      console.log(error);
    });
  };

  render() {
    return (
      <div className="dashboard-ui">
        <div className="sidebar">
          <UserInfo currentUser={this.props.currentUser} addToClass={"unset-min-height"} />
        </div>
        <div className="signup-page">
          <div className="signup">
            <h3>Sentinel LiME</h3>
            <h5>User Registration</h5>
            {/* ********************* */}
            {/* Manually Create Users */}
            {/* ********************* */}
            <form>
              {this.state.error ? <p className="error-message">{this.state.error}</p> : null}
              <input
                name="name"
                type="text"
                value={this.state.name}
                placeholder="Name"
                pattern="^.{3,}$"
                title="Enter a valid name"
                required
                onChange={this.onChangeHandler}
              ></input>
              <input
                name="email"
                type="email"
                value={this.state.email}
                placeholder="Email"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                title="Enter a valid email!"
                required
                onChange={this.onChangeHandler}
              ></input>
              <input
                name="password01"
                type="password"
                value={this.state.password01}
                placeholder="Password"
                className={this.state.error ? "input-error" : ""}
                pattern="^.{6,}$"
                title="at least 6 characters"
                required
                onChange={this.onChangeHandler}
              ></input>
              <input
                name="password02"
                type="password"
                value={this.state.password02}
                placeholder="Confirm Password"
                className={this.state.error ? "input-error" : ""}
                pattern="^.{6,}$"
                title="at least 6 characters"
                required
                onChange={this.onChangeHandler}
              ></input>
              <button type="submit" value="Sign Up" onClick={this.manualSignUpHandler}>
                Sign Up
              </button>
            </form>
            {/* ********************* */}
            {/* Other Sign Up Options */}
            {/* ********************* */}
            <h6>Sign Up options</h6>
            <div className="signup-options">
              <button type="submit" value="Google Sign-UP" className="google-button" onClick={this.googleSignUpHandler}>
                Google Sign-Up
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
