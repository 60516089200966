import React from "react";
import "./toolbar.style.scss";

import DateRangeSelect from "../date-range-select/date-range-select.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterSelect: this.props.defaultFilter ? this.setDefaultFilterSelect() : "All",
      searchSelect: this.props.searchState ? this.setDefaultSearchSelect() : "licenseKey",
      searchInput: this.props.searchState ? this.setDefaultSearchInput() : "",
      dateRangeSelect: this.props.defaultDateRangeFilter ? this.setDefaultDateRangeSelect() : "createdAt"
    };
  }

  //--- Set Default State
  setDefaultFilterSelect = () => {
    return this.props.defaultFilter.by ? this.props.defaultFilter.by : "All";
  };

  setDefaultSearchSelect = () => {
    return this.props.searchState.in ? this.props.searchState.in : "licenseKey";
  };

  setDefaultSearchInput = () => {
    return this.props.searchState.keyword ? this.props.searchState.keyword : "";
  };

  setDefaultDateRangeSelect = () => {
    return this.props.defaultDateRangeFilter.by ? this.props.defaultDateRangeFilter.by : "createdAt";
  };
  //--- Set Default State

  handleFilterChange = event => {
    this.setState({ filterSelect: event.target.value });
    this.props.changeFilterState(event.target.value);
  };

  handleDateRangeSelectChange = event => {
    this.setState({ dateRangeSelect: event.target.value });
    this.props.changeDateRangeFilterState(event.target.value);
  };

  closeDateRangeFilter = () => {
    this.props.changeDateRangeFilterState(null, null, null, false);
  };

  handleSearchChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  initSearch = () => {
    if (this.state.searchInput.trim() !== "") {
      this.setState({ filterSelect: "All" });
      this.props.changeFilterState("All");
      this.props.changeSearchState(true, this.state.searchInput.trim(), this.state.searchSelect);
    }
    // else {
    //   this.props.changeSearchState(false);
    // }
  };

  searchOnEnter = event => {
    if (event.keyCode === 13) {
      this.initSearch();
      // console.log(event);
    }
  };

  closeSearch = () => {
    this.props.changeSearchState(false);
    this.props.changeFilterState("All");

    this.setState({ searchInput: "", filterSelect: "All" });
  };

  refreshHandler = event => {
    if (!event.currentTarget.classList.contains("refresh-inactive")) {
      this.props.triggerReload();

      if (this.props.resetAllStoredReadlogs) {
        this.props.resetAllStoredReadlogs("Reset Point on Toolbar Refresh Handler");
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.searchState &&
      this.props.searchState.isActive &&
      prevProps.searchState.in !== this.state.searchSelect &&
      this.state.searchInput.trim() !== ""
    ) {
      this.props.changeSearchState(true, this.state.searchInput.trim(), this.state.searchSelect);
    }

    if (this.props.defaultDateRangeFilter.by !== this.state.dateRangeSelect) {
      this.setState({ dateRangeSelect: this.props.defaultDateRangeFilter.by });
    }

    if (this.props.defaultFilter && this.props.defaultFilter.by !== this.state.filterSelect) {
      this.setState({ filterSelect: this.props.defaultFilter.by });
    }
  }

  render() {
    return (
      <div className="toolbar">
        {/* -------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------------- SEARCH BAR ---------------------------------------- */}
        {/* -------------------------------------------------------------------------------------------- */}
        <div className="left-side">
          {this.props.searchState ? (
            <div className="search">
              <div className="search-wrapper">
                {/* Search Select */}
                <select
                  name="searchSelect"
                  value={this.state.searchSelect}
                  onChange={this.handleSearchChange}
                  disabled={this.props.collectionType === "keys" ? true : false}
                >
                  <option value="licenseKey" label="🔑" />
                  <option value="hardwareToken" label="💻" />
                </select>
                {/* Search Input */}
                <input
                  id="tb-search-id"
                  name="searchInput"
                  placeholder="Search..."
                  value={this.state.searchInput}
                  onChange={this.handleSearchChange}
                  onKeyUp={this.searchOnEnter}
                ></input>
                {/* Search/Close Button */}
                {this.props.searchState.isActive ? (
                  <FontAwesomeIcon className="search-close-button" onClick={this.closeSearch} icon="times-circle" />
                ) : (
                  <FontAwesomeIcon className="search-button" onClick={this.initSearch} icon="search" />
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div className="right-side">
          <div className="tb-button-wrapper">
            {/* ------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------ REFRESH --------------------------------------- */}
            {/* ------------------------------------------------------------------------------------------ */}
            <div
              title="Refresh"
              className={`toolbar-button ${this.props.loadingView.is ? "refresh-inactive" : ""}`}
              onClick={this.refreshHandler}
            >
              <FontAwesomeIcon icon="sync" />
            </div>
          </div>

          {/* ------------------------------------------------------------------------------------------ */}
          {/* ---------------------------------------- FILTER BY --------------------------------------- */}
          {/* ------------------------------------------------------------------------------------------ */}
          <div className="filter-by">
            {/* Filter By Date */}
            {this.props.collectionType !== "sentinel-overview" ? (
              <div className="by-date">
                <p className={`fb-label ${this.props.searchState.isActive ? "fbl-disabled" : ""}`}>Filter By Date:</p>
                <DateRangeSelect
                  minDateRange={this.props.databaseStartingPoint}
                  dateFrom={this.props.defaultDateRangeFilter.dateFrom}
                  dateTo={this.props.defaultDateRangeFilter.dateTo}
                  changeDateRangeFilterState={this.props.changeDateRangeFilterState}
                  disabled={this.props.searchState.isActive ? true : false}
                />
                <select
                  value={this.state.dateRangeSelect}
                  onChange={this.handleDateRangeSelectChange}
                  disabled={this.props.searchState.isActive ? true : false}
                >
                  <option value="createdAt">Created At</option>
                  {this.props.collectionType === "records" ? <option value="lastReadAt">Last Read At</option> : null}
                  {this.props.collectionType === "keys" ? <option value="modifiedAt">Modified At</option> : null}
                </select>
              </div>
            ) : (
              <div className="by-date">
                <p className={`fb-label`}>Filter By Date:</p>
                <DateRangeSelect
                  minDateRange={this.props.databaseStartingPoint}
                  dateFrom={
                    this.props.defaultDateRangeFilter.active ? this.props.defaultDateRangeFilter.dateFrom : null
                  }
                  dateTo={this.props.defaultDateRangeFilter.active ? this.props.defaultDateRangeFilter.dateTo : null}
                  changeDateRangeFilterState={this.props.changeDateRangeFilterState}
                  alignPopUp="right"
                />
                {this.props.defaultDateRangeFilter.active ? (
                  <FontAwesomeIcon
                    className="date-range-close-button"
                    onClick={this.closeDateRangeFilter}
                    icon="undo"
                    title="Clear Date Range"
                  />
                ) : null}
              </div>
            )}
            {/* Filter By Date */}

            {/* Filter By Status */}
            {this.props.defaultFilter ? (
              <div className="by-status">
                <p
                  className={`fb-label ${
                    this.props.searchState.isActive && this.props.collectionType === "keys" ? "fbl-disabled" : ""
                  }`}
                >
                  Status:
                </p>
                <select
                  id="filter-select"
                  value={this.state.filterSelect}
                  onChange={this.handleFilterChange}
                  disabled={this.props.searchState.isActive && this.props.collectionType === "keys" ? true : false}
                >
                  <option value="All">All</option>
                  <option value="Active">Active</option>
                  {this.props.collectionType === "keys" ? <option value="Activated">Activated</option> : null}
                  <option value="Blocked">Blocked</option>
                  <option value="Refunded">Refunded</option>
                  <option value="Transferred">Transferred</option>
                  {this.props.collectionType === "keys" ? <option value="Leaked">Leaked</option> : null}
                  {this.props.collectionType === "keys" ? <option value="Modified">Modified</option> : null}
                  {this.props.collectionType === "keys" ? (
                    <option value="ManuallyCreated">Manually Created</option>
                  ) : null}
                </select>
              </div>
            ) : null}
            {/* Filter By Status */}
          </div>
        </div>
      </div>
    );
  }
}

export default Toolbar;
