import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import KeyOverview from "./key-overview/key-overview.component";
import Timeline from "./timeline/timeline.component";
import DeviceHistory from "./device-history/device-history.component";
import ReadLog from "./readlog/readlog.component";
import DashboardLoading from "../loading/dashboard-loading.component";

class KeyInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docID: this.props.docID,
      currentDocData: null,
      popUpNav: this.props.popUpNav,
      activeNav: this.props.popUpNav[0].key,
      loadingReadlog: false,
      showReadlogFor: null
    };
  }

  //---------- ---------------- ------------ CONFIGURING POP UP ---------- ---------------- ------------
  setCurrentDocData = () => {
    let currDoc = null;
    this.props.currentDataCollection.forEach(element => {
      if (element.docID === this.props.docID) {
        currDoc = element;
      }
    });

    if (currDoc) {
      currDoc.sortedEventArray = this.extractEventsByOldestFirst(currDoc.eventList);
    }

    this.setState({ currentDocData: currDoc }, () => {
      // console.log(this.state.currentDocData);
      if (!currDoc) {
        this.props.togglePopUpView();
      }
    });
  };

  extractEventsByOldestFirst = eventList => {
    let sortByOldestFirst = [];
    let eventCount = Object.entries(eventList).length;

    // console.log(eventList);
    // console.log(eventCount);

    for (let i = 0; i < eventCount; i++) {
      let eventID = `event${i}`;

      if (eventList[eventID]) {
        sortByOldestFirst.push([eventID, eventList[eventID]]);
      }
    }

    // console.log(sortByOldestFirst);
    return sortByOldestFirst;
  };

  getPopUpNav = () => {
    return (
      <React.Fragment>
        {this.state.popUpNav.map(navItem => (
          <div
            id={navItem.key}
            className={`nav-item ${navItem.key === this.state.activeNav ? "pu-active-nav" : ""}`}
            key={navItem.key}
            onClick={this.handlePopUpNav}
          >
            {navItem.label}
          </div>
        ))}
      </React.Fragment>
    );
  };

  //---------- ---------------- --------------- UI HANDLERS -------------- ---------------- ------------
  handlePopUpNav = event => {
    this.setState({ activeNav: event.target.id });
  };

  refreshHandler = event => {
    if (!event.currentTarget.classList.contains("refresh-inactive")) {
      if (this.props.triggerReload) {
        this.props.triggerReload(false);

        this.props.resetAllStoredReadlogs("Readlog Reset Point on Key Info Refresh Handler");
      }
    }
  };

  setShowReadlogForState = hwObject => {
    this.setState({ showReadlogFor: hwObject });
  };

  setLoadingReadlogState = newState => {
    this.setState({ loadingReadlog: newState });
  };

  handleOutsideClicks = event => {
    if (this.state.showReadlogFor) {
      let readlogPopUpElement = document.getElementById("id-fixed-pop-up");
      let mainPoUpHeaderElement = document.getElementById("id-pop-up-header");

      if (!readlogPopUpElement.contains(event.target) && !mainPoUpHeaderElement.contains(event.target)) {
        this.setState({ showReadlogFor: null });
      }
    }
  };

  //---------- --------------- --------------- REACT FUNCTIONS ------------- -------------- ------------
  componentDidMount() {
    this.setCurrentDocData();
    document.addEventListener("mousedown", this.handleOutsideClicks);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentDataCollection !== this.props.currentDataCollection) {
      this.setCurrentDocData();

      if (this.state.showReadlogFor) {
        this.setLoadingReadlogState(true);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClicks);
  }

  render() {
    return (
      <React.Fragment>
        {/* ------------ ----------------- ---------- POP UP HEADER ------------ ----------------- ---------- */}
        {this.state.loadingReadlog ? <DashboardLoading loadingMessage={null} fixedPosition={true} /> : null}
        <div id="id-pop-up-header" className="pop-up-header">
          <div className="pu-header-left">
            {/* Close Button */}
            <div
              className="pop-up-close"
              onClick={() => {
                this.props.togglePopUpView();
              }}
            >
              <FontAwesomeIcon icon="arrow-left" size="1x" />
            </div>

            {/* Heading */}
            <p className="pop-up-heading">{`License Key : ${this.state.docID}`}</p>
          </div>

          <div className="pu-header-right">
            {this.props.triggerReload ? (
              <div
                title="Reload"
                className={`refresh ${this.props.loadingView.is ? "refresh-inactive" : ""}`}
                onClick={this.refreshHandler}
              >
                <FontAwesomeIcon icon="sync" />
              </div>
            ) : null}
            {this.state.popUpNav ? <div className="pop-up-nav">{this.getPopUpNav()}</div> : null}
          </div>
        </div>

        {/* ------------ ----------------- ---------- POP UP BODY ------------ ----------------- ---------- */}
        <div className="pop-up-body">
          {/* KEY OVERVIEW */}
          {this.state.activeNav === "overview" && this.state.currentDocData ? (
            <KeyOverview
              keyData={this.state.currentDocData}
              updateKeysCollection={this.props.updateKeysCollection}
              setShowReadlogForState={this.setShowReadlogForState}
            />
          ) : null}
          {/* KEY OVERVIEW */}

          {/* DEVICE HISTORY */}
          {this.state.activeNav === "device-history" && this.state.currentDocData ? (
            <DeviceHistory keyData={this.state.currentDocData} setShowReadlogForState={this.setShowReadlogForState} />
          ) : null}
          {/* DEVICE HISTORY */}

          {/* TIMELINE */}
          {this.state.activeNav === "timeline" && this.state.currentDocData ? (
            <Timeline keyData={this.state.currentDocData} />
          ) : null}
          {/* TIMELINE */}

          {/* READLOG */}
          {this.state.currentDocData && this.state.showReadlogFor ? (
            <div className="fixed-pop-up-wrapper">
              <div id="id-fixed-pop-up" className="fixed-pop-up">
                <div
                  className="fpu-close-btn"
                  onClick={() => {
                    this.setShowReadlogForState(null);
                  }}
                >
                  <FontAwesomeIcon icon="times-circle" />
                </div>
                <ReadLog
                  docID={this.state.showReadlogFor.recordID}
                  loadingReadlog={this.state.loadingReadlog}
                  setLoadingReadlogState={this.setLoadingReadlogState}
                  storedReadlogs={this.props.storedReadlogs}
                  setStoredReadlogs={this.props.setStoredReadlogs}
                  calendarToolbarLabel={{
                    property: `Readlog :`,
                    value: this.state.showReadlogFor.hwt
                  }}
                  footerLabel={{ property: "License Key:", value: this.state.currentDocData.licenseKey }}
                />
              </div>
            </div>
          ) : null}
          {/* READLOG */}
        </div>
      </React.Fragment>
    );
  }
}

export default KeyInfo;
