import React from "react";

import "./main-view.style.scss";
import { RECORDS_TABLE_HEADINGS, KEYS_TABLE_HEADINGS } from "../../dashboard-config/dashboard-config";

import SentinelOverview from "../sentinel-overview/sentinel-overview.component";
import DataTable from "../data-table/data-table.component";
import AddKey from "../add-key/add-key.component";

const MainView = ({
  appOPState,
  sentinelOverviewCollection,
  sentinelOverviewDateRangeFilter,
  recordsCollection,
  recordsSorting,
  keysCollection,
  keysSorting,
  changeSortingState,
  changeClipboardState,
  togglePopUpView,
  updateKeysCollection
}) => (
  <div className={`main-view ${appOPState === "sentinel-overview" ? "main-view-without-footer" : ""}`}>
    {appOPState === "sentinel-overview" && sentinelOverviewCollection ? (
      <React.Fragment>
        {Object.keys(sentinelOverviewCollection).length > 0 ? (
          <div className="view-wrapper">
            <SentinelOverview
              sentinelOverviewCollection={sentinelOverviewCollection}
              sentinelOverviewDateRangeFilter={sentinelOverviewDateRangeFilter}
            />
          </div>
        ) : (
          <div className="empty-collection-label">
            <p>No Data</p>
          </div>
        )}
      </React.Fragment>
    ) : null}

    {appOPState === "records" && recordsCollection ? (
      <React.Fragment>
        {Object.keys(recordsCollection).length > 0 ? (
          <div className="view-wrapper">
            <DataTable
              collectionType={appOPState}
              tableHeadings={RECORDS_TABLE_HEADINGS}
              dataCollection={recordsCollection}
              sortingConfig={recordsSorting}
              changeSortingState={changeSortingState}
              changeClipboardState={changeClipboardState}
              togglePopUpView={togglePopUpView}
            />
          </div>
        ) : (
          <div className="empty-collection-label">
            <p>No Data</p>
          </div>
        )}
      </React.Fragment>
    ) : null}

    {appOPState === "keys" && keysCollection ? (
      <React.Fragment>
        <div className="view-wrapper">
          {Object.keys(keysCollection).length > 0 ? (
            <DataTable
              collectionType={appOPState}
              tableHeadings={KEYS_TABLE_HEADINGS}
              dataCollection={keysCollection}
              sortingConfig={keysSorting}
              changeSortingState={changeSortingState}
              changeClipboardState={changeClipboardState}
              togglePopUpView={togglePopUpView}
            />
          ) : (
            <div className="empty-collection-label">
              <p>No Data</p>
            </div>
          )}
          <AddKey updateKeysCollection={updateKeysCollection} />
        </div>
      </React.Fragment>
    ) : null}
  </div>
);

export default MainView;
